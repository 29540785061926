/* eslint-disable no-underscore-dangle */
import { flattenRoutes } from 'utils/routes'
import { INVOICES_ROUTES } from 'views/Invoices/consts'
import { PROMOTIONS_ROUTES } from 'views/Promotions/consts'
import { ORDERS_ROUTES } from 'views/Orders/consts'
import { PROFILE_ROUTES } from 'containers/Profile/consts'

export const {
  API_URL,
  RECAPTCHA_KEY,
  GTM_ID,
  SENTRY_DSN,
  SENTRY_ENV,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIRESTORE_COLLECTION,
  FIREBASE_MEASUREMENT_ID,
  TIDIO_STORE_IDS,
  KNOCK_API_KEY,
  KNOCK_IN_APP_CHANNEL,
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_HOST,
  GOOGLE_RETAIL_GCP_PROJECT_ID,
  GOOGLE_RETAIL_KEY,
} = window._env_ || process.env

export const BADGE_HEIGTH = 24
export const TEMPLATE_MAX_LENGTH = 150

export const RESET_PASSWORD_ROUTES = {
  INIT: '/reset-password',
  CONFIRM: '/reset-password/:token',
}

export const ROUTES = {
  DASHBOARD: '/',
  LOGIN: '/login',
  CONTACT: '/contact',
  CATEGORIES: '/categories',
  CAMPAIGN_DETAILS: '/campaigns/:id',
  ALL_PRODUCTS: '/products',
  PRODUCTS_ROOT: '/products/*',
  PRODUCTS: '/products/:category?/:group?/:subgroup?',
  PROMO_PRODUCTS: '/products?filters[promotion]=1',
  CLEARANCE_PRODUCTS: '/products?filters[clearance]=1',
  RECOMMENDED_PRODUCTS: '/products?filters[offerFeatures][]=RECOMMENDATION',
  NOVELTY_PRODUCTS: '/products?filters[offerFeatures][]=NOVELTY',
  BESTSELLER_PRODUCTS: '/products?filters[offerFeatures][]=BESTSELLER',
  SEARCH_RESULTS: '/search-results',
  PRODUCT_DETAILS: '/product/:id',
  CART: '/cart',
  CART_MISSING_ITEMS: '/cart-missing',
  ORDER_SUMMARY: '/order-summary',
  NOT_FOUND: '/not-found',
  TEMPLATES: '/templates',
  TEMPLATE_DETAILS: '/templates/:id?',
  SIGN_UP: '/sign-up',
  SIGN_UP_MIGRATE: '/migrate/:token',
  EMAIL_CONFIRMATION: '/email-confirm/:token',
  SET_PASSWORD: '/set-password/:token',
  PROFILE: '/profile',
  CHANGE_PASSWORD_CONFIRM: '/change-password/confirm',
  RESET_PASSWORD: '/reset-password/:token?',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  NOT_ALL_ADDED: '/not-all-added',
  SET_LOCATION: '/set-location',
  CHANGE_LOCATION: '/change-location',
  NEWSLETTER_SUBSCRIBE_EMAIL_CONFIRM: '/newsletter-confirm/:token',
  NEWSLETTER_UNSUBSCRIBE_EMAIL_CONFIRM: '/newsletter-unsubscribe/:token',
  ORDERS: ORDERS_ROUTES,
  INVOICES: INVOICES_ROUTES,
  PROMOTIONS: PROMOTIONS_ROUTES,
  RESET_PASSWORD_ROUTES,
  PROFILE_ROUTES,
}

export const UNAUTHENTICATED_ONLY_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.SIGN_UP,
  ROUTES.CHANGE_PASSWORD_CONFIRM,
]

export const ALWAYS_ENTERABLE_ROUTES = [
  ROUTES.TERMS,
  ROUTES.PRIVACY,
  ROUTES.CONTACT,
  ROUTES.EMAIL_CONFIRMATION,
  ROUTES.SET_PASSWORD,
  ROUTES.NEWSLETTER_SUBSCRIBE_EMAIL_CONFIRM,
  ROUTES.NEWSLETTER_UNSUBSCRIBE_EMAIL_CONFIRM,
  PROFILE_ROUTES.NEWSLETTER_UNSUBSCRIBE_SUCCESS,
  PROFILE_ROUTES.NEWSLETTER_FEEDBACK,
  ROUTES.SIGN_UP_MIGRATE,
  RESET_PASSWORD_ROUTES.INIT,
  RESET_PASSWORD_ROUTES.CONFIRM,
]

// TODO: refactor routes to be simple key - value pair array?
export const FLATTENED_ROUTES = flattenRoutes(Object.values(ROUTES))

export const DEFAULT_PAGE_SIZE = 24
export const DEFAULT_PRODUCT_PAGE_SIZE = 35
export const MOBILE_PAGINATION_BREAKPOINT = 762

export const Z_INDEX = {
  1: '1',
  2: '2',
  5: '5',
  10: '10',
  20: '20',
  50: '50',
  100: '100',
}

export const KEY_CODES = {
  ESC: 'Escape',
}

export const MAX_INPUT_VALUE = 999999

export const APP_BREAKPOINTS = {
  MOBILE_WIDE_START: 420,
  TABLET_START: 720,
  DESKTOP_START: 1021,
  TMP_MAIN: 1280,
  DESKTOP_WIDE_START: 1440,
}

export const APPS_ROUTES = {
  APP_STORE: 'https://apps.apple.com/pl/app/e-bidfood/id1494625203',
  GOOGLE_PLAY:
    'https://play.google.com/store/apps/details?id=com.farutexmobile',
}

export const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: 'e-bidfood.firebaseapp.com',
  databaseURL: 'https://e-bidfood.firebaseio.com',
  projectId: 'e-bidfood',
  storageBucket: 'e-bidfood.appspot.com',
  messagingSenderId: '955637032329',
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
}

export const CURRENCY = 'PLN'

export const STORE_IDS = {
  LODZ: 3,
  NT: 26,
}
