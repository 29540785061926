import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import TextInput from 'components/Fields/TextInput'
import TwButton from 'components/Button/TwButton'
import { RadioBtn } from 'components/Icons'
import LoaderFullHeight from 'components/LoaderFullHeight'
import { userDataSelector } from 'containers/UserInfo/selectors'
import OTP from 'components/OTP'
import { useToggle2FAHandlers } from 'views/Profile/utils'
import { useRichFormatMessage } from 'utils'

import messages from './messages'

const Activate2FA = () => {
  const formatMessage = useRichFormatMessage()
  const { customerEmail } = useSelector(userDataSelector)

  const [otpData, setOTPData] = useState(null)
  const [otpError, setOTPError] = useState(null)

  const {
    handleRequestOTP,
    handleSubmitOTP,
    isRequestOTPLoading,
    isToggle2FALoading,
  } = useToggle2FAHandlers({
    twoFactorEnabled: false,
    onRequestOTPSuccess: data => {
      setOTPData(data)
    },
    onInvalidOTP: () => {
      setOTPError(true)
    },
  })

  const isLoading = isToggle2FALoading || isRequestOTPLoading

  if (otpData) {
    return (
      <>
        {isLoading && <LoaderFullHeight />}
        <OTP
          onSubmit={handleSubmitOTP}
          requestOtp={handleRequestOTP}
          otpData={otpData}
          description={formatMessage(messages.otpDescription, {
            email: otpData?.email,
          })}
          submitText={formatMessage(messages.otpSubmit)}
          otpError={otpError}
          setOtpError={setOTPError}
          isOtpLoading={isRequestOTPLoading}
        />
      </>
    )
  }

  return (
    <>
      {isLoading && <LoaderFullHeight />}
      <div className="px-4 py-10 mb-10 flex flex-col gap-8 md:w-[600px] md:my-20 md:mx-auto md:p-0">
        <span className="text-[28px] leading-10 font-semibold text-blue-900">
          {formatMessage(messages.header)}
        </span>

        <div className="flex flex-col gap-6">
          <span className="text-sm text-blue-900">
            {formatMessage(messages.description)}
          </span>

          <div className="flex gap-4 p-4 rounded-lg bg-grey-150 items-center">
            <RadioBtn />
            <span className="text-[13px] leading-5 text-blue-900">
              {formatMessage(messages.emailOption)}
            </span>
          </div>

          <TextInput
            label={formatMessage(messages.emailLabel)}
            labelClassName="px-2"
            inputClassName="text-grey-500"
            disabled
            value={customerEmail}
            hint={formatMessage(messages.emailHint)}
          />
        </div>

        <TwButton onClick={handleRequestOTP}>
          <span className="text-[13px] leading-6">
            {formatMessage(messages.begin)}
          </span>
        </TwButton>
      </div>
    </>
  )
}

export default Activate2FA
