import { INVOICE_TYPE_IDS } from 'containers/Invoices/List/Filters'

export const SCREENS = {
  CART: 'cart',
  TEMPLATES: 'templates listing',
  TEMPLATE_DETAILS: 'template details',
  ORDERS: 'order history listing',
  ORDER_SUMMARY: 'order history details',
  PRODUCTS: 'category products listing',
  PDP: 'PDP',
  CAMPAIGN_DETAILS: 'campaign details',
  INVOICES: 'invoice history listing',
}

export const EVENTS = {
  PAGE_VIEW: 'Pageview',
  PURCHASE: 'purchase',
  INVALID_CREDENTIALS: 'login_credentials_failed',
}

export const NAV_BAR_EVENTS = {
  MAIN_LOGO_CLICKED: 'Clicked main logo',
  SEARCH_FIELD_CLICKED: 'Clicked search field',
  PROMOTIONS_CLICKED: 'Clicked promotions',
  CATALOG_CLICKED: 'clicked_catalog',
  TEMPLATES_CLICKED: 'Clicked templates',
  USER_MENU_CLICKED: 'Clicked user menu',
  CART_CLICKED: 'Clicked cart',
  SWITCH_ACCOUNT_CLICKED: 'clicked_switch_account',
  SETTINGS_CLICKED: 'Clicked data and settings',
  ORDERS_CLICKED: 'Clicked orders',
  LOGOUT_CLICKED: 'Clicked log out',
  MAIN_PAGE_CLICKED: 'clicked_main_page',
  ALL_PRODUCTS_CLICKED: 'clicked_all_products',
  CUISINES_CLICKED: 'clicked_cuisines',
  CATEGORIES_CLICKED: 'clicked_categories',
  CATEGORY_CLICKED: 'clicked_category',
  SUBCATEGORY_CLICKED: 'clicked_subcategory',
  SEE_ALL_CATEGORY_CLICKED: 'clicked_see_all_category',
}

export const PROFILE_EVENTS = {
  TERMS_CLICKED: 'Clicked terms and conditions',
  NEWSLETTER_CLICKED: 'Clicked newsletter',
  MARKETING_CLICKED: 'Clicked marketing consents',
  CONTACT_CLICKED: 'Clicked contact details',
  CONTACT_FORM_CLICKED: 'clicked_contact_form',
}

export const CART_EVENTS = {
  ADD_TO_CART: 'add_to_cart',
  ADDED_PRIZE_TO_CART: 'Added prize product to cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  REMOVED_ALL_FROM_CART: 'Removed product from cart',
  INCREASED_CART_QUANTITY: 'Increased quantity',
  DECREASED_CART_QUANTITY: 'Decreased quantity',
}

export const NOTIFICATION_EVENTS = {
  NOTIFICATIONS_CLICKED: 'clicked_in_app_notification_bell',
  NOTIFICATION_CLICK: 'clicked_in_app_notification',
}

export const FOOTER_EVENTS = {
  CONTACTS_CLICK: 'Clicked contact with sales',
}

export const INVOICE_TYPE_EVENTS = {
  [INVOICE_TYPE_IDS.ALL]: 'Selected all payments',
  [INVOICE_TYPE_IDS.TO_PAY]: 'Selected due payments',
  [INVOICE_TYPE_IDS.OVERPAID]: 'Selected overpayments',
  [INVOICE_TYPE_IDS.PAID]: 'Selected paid payments',
}

export const ITEM_LIST_NAMES = {
  PROMO_TILE: 'Promo Tile',
  PROMO_MODAL: 'Promo Modal',
  SEARCH: 'Search',
  SEARCH_RESULTS: 'search_results',
  TEMPLATE: 'Template',
  SUBSTITUTES: 'Substitutes',
  CART: 'Cart',
  RECOMMENDATION_MAIN: 'recommendation_main',
  RECOMMENDATION_ALL_PRODUCTS: 'recommendation_all_prod',
  CATALOG: 'catalog',
  ORDER_DETAILS: 'order_details',
  CAMPAIGNS: 'campaign',
  RECOMMENDATION_PDP: 'recommendation_PDP',
  PRODUCT_DETAILS: 'product_details',
}

export const ITEM_LIST_IDS = {
  SEARCH: 'search',
  SEARCH_RESULTS: 'search_results',
  SUBSTITUTES: 'substitutes',
  CART: 'cart',
  RECOMMENDATION_MAIN: 'recommendation_main',
  RECOMMENDATION_ALL_PRODUCTS: 'recommendation_all_prod',
  RECOMMENDATION_CATEGORY: 'recommendation_',
  CATALOG: 'catalog',
  ORDER_DETAILS: 'order_details',
  RECOMMENDATION_PDP: 'recommendation_PDP',
  PRODUCT_DETAILS: 'product_details',
}

export const EVENT_ORIGIN = {
  DASHBOARD: 'dashboard',
  CART: 'cart',
  DROPDOWN_MENU: 'dropdown_menu',
  NAVIGATION: 'navigation',
  NOTIFICATION_REDIRECT: 'notification_redirect',
  PAST_ORDER: 'past_order',
  USER_MENU: 'user_menu',
  USER_SETTINGS: 'data_and_settings',
}

export const ALL_RECOMMENDED_ORIGINS = {
  DROPDOWN: 'dropdown_menu',
  DASHBOARD: 'dashboard',
}

export const ALL_NOVELTIES_ORIGINS = {
  DROPDOWN: 'dropdown_menu',
  DASHBOARD: 'dashboard',
}

export const ALL_BESTSELLERS_ORIGINS = {
  DROPDOWN: 'dropdown_menu',
  DASHBOARD: 'dashboard',
}

export const ALL_PROMO_ORIGINS = {
  TILE: 'promo_tile',
  DROPDOWN: 'dropdown_menu',
  DASHBOARD: 'dashboard',
  NAV: 'navigation',
}

export const CATALOG_EVENTS = {
  SEARCH: 'search',
  VIEW_SEARCH_RESULTS: 'view_search_results',
  EMPTY_SEARCH_RESULTS: 'empty_search_results',
  SELECT_ITEM: 'select_item',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
}

export const PDP_EVENTS = {
  SEE_REPLACEMENTS_CLICKED: 'clicked_see_replacements',
  PHOTO_CLICKED: 'photo_view',
}

export const PRODUCT_EVENTS = {
  OPEN_NOTIFICATION_MODAL_CLICKED: 'clicked_prod_avail_notification',
  SETUP_NOTIFICATION: 'set_prod_avail_notification',
}

export const AVAILABILITY_STATUS = {
  OUT_OF_OFFER: 'out of offer',
  OUT_OF_STOCK: 'out of stock',
  AVAILABLE_SOON: 'available soon',
}
