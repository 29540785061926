import React from 'react'

const Kebab = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="11" width="2" height="24" rx="1" fill="#131B4D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50105 6.84126C5.22126 5.25579 6.24628 3.73321 7.8205 3.39587C10.5754 2.80554 13.4241 2.80554 16.1789 3.39587C17.7531 3.73321 18.7782 5.25579 18.4984 6.84126L18.1968 8.55041C17.2027 7.85273 16.1597 7.31097 15.2938 7.04454C14.766 6.88212 14.2064 7.17837 14.044 7.70623C13.8815 8.23409 14.1778 8.79368 14.7056 8.9561C15.5312 9.21011 16.7344 9.87786 17.8059 10.7653L17.0857 14.8466C16.4557 14.4898 15.8396 14.2125 15.2938 14.0445C14.766 13.8821 14.2064 14.1784 14.044 14.7062C13.8815 15.2341 14.1778 15.7937 14.7056 15.9561C15.2568 16.1257 15.9764 16.4797 16.7126 16.9604L16.5829 17.6955C16.2456 19.6069 14.5848 21.0003 12.6438 21.0003H11.3556C9.41467 21.0003 7.75381 19.6069 7.4165 17.6955L7.28678 16.9604C8.02307 16.4797 8.74259 16.1257 9.29378 15.9561C9.82164 15.7937 10.1179 15.2341 9.95547 14.7062C9.79305 14.1784 9.23347 13.8821 8.7056 14.0445C8.15981 14.2125 7.54367 14.4898 6.91376 14.8466L6.19352 10.7653C7.26504 9.87786 8.46823 9.21011 9.29378 8.9561C9.82164 8.79368 10.1179 8.23409 9.95547 7.70623C9.79305 7.17837 9.23347 6.88212 8.7056 7.04454C7.83971 7.31097 6.79676 7.85273 5.80267 8.55041L5.50105 6.84126Z"
      fill="#964F39"
    />
  </svg>
)

export default Kebab
