import { rtkApi } from 'services/api/rtkApi'

const payProcessingApi = rtkApi.injectEndpoints({
  endpoints: build => ({
    getPayStatus: build.query({
      query: ({ paymentId }) => ({
        url: `/me/payments/${paymentId}`,
      }),
    }),
  }),
})

export const { useGetPayStatusQuery } = payProcessingApi
