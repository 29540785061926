import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import theme from 'theme'
import { useOfMaxWidth } from 'hooks'
import { Flex } from '@rebass/grid'
import { matchPath, useLocation } from 'react-router-dom'
import { compose } from 'lodash/fp'

import { ROUTES, APP_BREAKPOINTS } from 'consts'
import { withSpinner } from 'hocs'
import userActions from 'containers/UserInfo/actions'
import { USER_INFO_REDUCER_NAME } from 'containers/UserInfo/consts'
import { authTokenSelector } from 'containers/Auth/selectors'
import { allCategoriesListActions } from 'containers/Categories/actions'
import injectSaga from 'utils/injectSaga'
import saga from 'containers/UserInfo/sagas'
import Notifications from './Notifications'

import Footer from './Footer'
import { PageContent } from './styles'

import Header from './Header'

const LoggedInLayout = ({ children }) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const token = useSelector(authTokenSelector)
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const isInvoiceProcessingRoute = useMemo(
    () => {
      const match = matchPath(
        {
          path: ROUTES.INVOICES.PAY_PROCESSING,
          end: true,
        },
        pathname,
      )

      return !!match
    },
    [pathname],
  )
  const isLoggedIn = isInvoiceProcessingRoute ? false : !!token

  useEffect(
    () => {
      if (token) {
        dispatch(userActions.delta())
        // TODO: replace with RTK query hook
        dispatch(allCategoriesListActions.delta())
      }
    },
    [token],
  )

  if (pathname === ROUTES.LOGIN) {
    return (
      <>
        <Notifications isMobile={isMobile} />
        {children}
      </>
    )
  }

  return (
    <Flex
      flexDirection="column"
      style={{ minHeight: '100vh' }}
      bg={
        [ROUTES.CART, ROUTES.CART_MISSING_ITEMS].includes(pathname)
          ? theme.colors.bgGray
          : ''
      }
    >
      <Notifications {...{ isMobile }} />
      <Header pathname={pathname} isLoggedIn={isLoggedIn} />

      <PageContent>{children}</PageContent>

      <Footer />
    </Flex>
  )
}

export default compose(
  injectSaga({ key: USER_INFO_REDUCER_NAME, saga }),
  withSpinner([USER_INFO_REDUCER_NAME]),
)(LoggedInLayout)
