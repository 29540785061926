import React from 'react'

import { cn } from 'utils'

const Mail = ({ width = 24, height = 24, className, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={cn('stroke-blue-900', className)}
    {...rest}
  >
    <path
      d="M3 17V7C3 5.89543 3.89543 5 5 5H19C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-inherit"
    />
    <path
      d="M4 6L9.87868 11.8787C11.0503 13.0503 12.9497 13.0503 14.1213 11.8787L20 6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-inherit"
    />
  </svg>
)

export default Mail
