import React from 'react'

import theme from 'theme'

const CircleInfo = ({ color = theme.colors.gray5 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="2" />
    <path
      d="M10 10.5V10C10 8.89543 10.8954 8 12 8V8C13.1046 8 14 8.89543 14 10V10C14 10.6295 13.7036 11.2223 13.2 11.6L12.8 11.9C12.2964 12.2777 12 12.8705 12 13.5V14"
      stroke={color}
      strokeWidth="2"
    />
    <circle cx="12" cy="16" r="1" fill={color} />
  </svg>
)

export default CircleInfo
