import React from 'react'

import ArrowIcon from 'components/Icons/ArrowFullLeft'
import CategoriesList from 'components/KnowledgeBase/CategoriesList'
import { useKnowledgeBaseCategories } from 'components/KnowledgeBase/utils'
import ListItemSkeleton from 'components/ListItemSkeleton'
import { getAuthToken } from 'containers/Auth/tokenStorage'

const KnowledgeBaseMenu = ({ closeSideMenu, mobileMenuOffset }) => {
  const token = getAuthToken()
  const { categories, isLoading } = useKnowledgeBaseCategories()

  return (
    <div
      className="flex flex-col overflow-y-auto"
      style={{ height: `calc(100vh - ${mobileMenuOffset}px)` }}
    >
      {!!token && (
        <button
          type="button"
          data-test-id="return-btn"
          className="flex pt-6 pr-6 pl-4 items-center"
        >
          <ArrowIcon onClick={closeSideMenu} />
        </button>
      )}
      <div className="flex flex-col px-4">
        {isLoading ? (
          <>
            <ListItemSkeleton />
            <ListItemSkeleton />
            <ListItemSkeleton />
          </>
        ) : (
          <CategoriesList className="my-6" categories={categories} />
        )}
      </div>
    </div>
  )
}

export default KnowledgeBaseMenu
