import { makeMessages, makeTranslation } from 'utils'

const messages = {
  changeLocation: 'changeLocation',
  settings: 'settings',
  orders: 'orders',
  invoices: 'invoices',
  contactForm: { id: 'contactForm.title' },
  logout: 'logout',
  invoicesDues: 'invoicesDues',
  invoicesExpired: 'invoicesExpired',
  invoicesYellowMsg: 'invoicesMsgYellow',
  invoicesRedMsg: 'invoicesMsgRed',
  knowledgeBase: { id: 'knowledgeBase.mainTitle' },
}

export default makeMessages(messages, makeTranslation('navbar.menu'))
