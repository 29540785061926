import React from 'react'

import { ArrowDownV2Big, ArrowUpV2Big } from 'components/Icons'

const DropdownArrow = ({ isOpen, testIdPrefix = 'profile' }) =>
  isOpen ? (
    <ArrowUpV2Big
      data-test-id={`${testIdPrefix}-arrow-up`}
      className="stroke-blue-900"
    />
  ) : (
    <ArrowDownV2Big
      data-test-id={`${testIdPrefix}-arrow-down`}
      className="stroke-blue-900"
    />
  )

export default DropdownArrow
