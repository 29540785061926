export const INVOICE_TYPE_IDS = {
  ALL: 'all',
  TO_PAY: 'to_pay',
  PAID: 'paid',
  OVERPAID: 'overpaid',
  OVERDUE: 'overdue',
}

export const INVOICE_TYPE = new Map([
  [INVOICE_TYPE_IDS.TO_PAY, { id: INVOICE_TYPE_IDS.TO_PAY, title: 'toPay' }],
  [
    INVOICE_TYPE_IDS.OVERPAID,
    { id: INVOICE_TYPE_IDS.OVERPAID, title: 'overpaid' },
  ],
  [INVOICE_TYPE_IDS.PAID, { id: INVOICE_TYPE_IDS.PAID, title: 'paid' }],
  [INVOICE_TYPE_IDS.ALL, { id: INVOICE_TYPE_IDS.ALL, title: 'all' }],
])
