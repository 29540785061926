import React, { useState, useLayoutEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { ORDERS_ROUTES } from 'views/Orders/consts'
import {
  useGetAffectedOrdersQuery,
  useCancelOrderMutation,
} from 'containers/Orders/Details/rtkApi'
import { notifySuccess, notifyFailure } from 'components/Product/utils'
import { getAllDeliveryDates } from 'containers/Delivery/actions'
import LoaderFullHeight from 'components/LoaderFullHeight'
import Confirmation from 'components/modal/Confirmation'
import { DescriptionText } from 'components/modal/Confirmation/styles'
import AffectedOrdersList from './AffectedOrdersList'
import { OTHER_REASON_KEY } from './CancelReasonsList/consts'
import CancelReasonsList from './CancelReasonsList'

import messages from './messages'
import reasonMsgs from './CancelReasonsList/messages'
import { useCancelOrderModalTexts } from './utils'
import { ModalContentContainer } from './styles'

const CancelOrderModal = ({
  onClose,
  orderId,
  setIsSubmitting,
  onCancelSuccess,
  onCancelError,
}) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [selectedReasons, setSelectedReasons] = useState([])
  const [otherReason, setOtherReason] = useState('')
  const [isAffectedOrdersVariant, setAffectedOrdersVariant] = useState(false)

  const {
    data: { affectedOrders } = {},
    isFetching: isAffectedOrdersFetching,
  } = useGetAffectedOrdersQuery({ orderId })
  const affectedOrderIds = useMemo(
    () => affectedOrders?.map(order => order.id) || [],
    [affectedOrders],
  )
  const {
    headingText,
    topText,
    bottomText,
    leftBtnText,
    rightBtnText,
  } = useCancelOrderModalTexts(isAffectedOrdersVariant)

  // setting correct variant initial render to prevent flickering
  useLayoutEffect(
    () => {
      if (affectedOrders?.length) {
        setAffectedOrdersVariant(true)
      } else {
        setAffectedOrdersVariant(false)
      }
    },
    [affectedOrders],
  )

  const mapReasonsTranslations = reasonsKeys =>
    reasonsKeys.map(key => formatMessage(reasonMsgs[key]))
  const selectedReasonsTranslated = useMemo(
    () => mapReasonsTranslations(selectedReasons),
    [selectedReasons],
  )

  const getTranslatedReasons = () => {
    const isOtherReasonSelected = selectedReasons.includes(OTHER_REASON_KEY)

    if (isOtherReasonSelected) {
      const filteredReasons = selectedReasons.filter(
        reasonKey => reasonKey !== OTHER_REASON_KEY,
      )
      const filteredReasonsTranslated = mapReasonsTranslations(filteredReasons)

      const otherReasonText = formatMessage(reasonMsgs[OTHER_REASON_KEY])

      return [
        ...filteredReasonsTranslated,
        // if user filled other reason input, prefix it with default other reason text
        otherReason ? `${otherReasonText}: ${otherReason}` : otherReasonText,
      ]
    }

    return selectedReasonsTranslated
  }

  const [cancelOrder] = useCancelOrderMutation()
  const handleCancelOrder = async () => {
    setIsSubmitting(true)

    const { error } = await cancelOrder({
      orderId,
      affectedOrderIds,
      reasons: getTranslatedReasons(),
    })

    setIsSubmitting(false)

    if (error) {
      dispatch(notifyFailure(formatMessage(messages.error)))
      onCancelError()

      return
    }

    dispatch(notifySuccess(formatMessage(messages.success)))

    // refetch data for possible UI updates
    dispatch(getAllDeliveryDates.delta())

    onCancelSuccess({ reasonsParam: selectedReasonsTranslated })
  }

  const isConfirmDisabled = !isAffectedOrdersVariant && !selectedReasons.length
  const handleConfirm = () => {
    if (isAffectedOrdersVariant) {
      setAffectedOrdersVariant(false)
    } else {
      handleCancelOrder()
    }
  }

  const handleShowAffectedOrder = id => {
    onClose()
    navigate(generatePath(ORDERS_ROUTES.DETAILS, { orderId: id }))
  }

  if (isAffectedOrdersFetching) return <LoaderFullHeight />

  return (
    <Confirmation
      isOpen
      isWide
      onClose={onClose}
      onConfirm={handleConfirm}
      isConfirmDisabled={isConfirmDisabled}
      headingText={headingText}
      bottomText={bottomText}
      leftBtnText={leftBtnText}
      rightBtnText={rightBtnText}
      rightBtnTestId="cancel-order-modal-action"
    >
      <ModalContentContainer>
        <DescriptionText>{topText}</DescriptionText>
        {isAffectedOrdersVariant ? (
          <AffectedOrdersList
            orderId={orderId}
            affectedOrders={affectedOrders}
            handleShowAffectedOrder={handleShowAffectedOrder}
          />
        ) : (
          <CancelReasonsList
            selectedReasons={selectedReasons}
            setSelectedReasons={setSelectedReasons}
            otherReason={otherReason}
            setOtherReason={setOtherReason}
          />
        )}
      </ModalContentContainer>
    </Confirmation>
  )
}

export default CancelOrderModal
