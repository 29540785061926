import React, { useMemo } from 'react'

import { cn } from 'utils'
import { getAuthToken } from 'containers/Auth/tokenStorage'
import ListItem from './ListItem'

const CategoriesList = ({ categories, className }) => {
  const token = getAuthToken()
  const categoriesList = useMemo(
    () => {
      if (!token) return categories.filter(({ isPublic }) => isPublic)

      return categories
    },
    [token, categories],
  )

  return (
    <div className={cn('flex flex-col gap-3', className)}>
      {categoriesList?.map(category => (
        <ListItem key={category.title} category={category} />
      ))}
    </div>
  )
}

export default CategoriesList
