import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { userDataSelector } from 'containers/UserInfo/selectors'
import {
  trackSimpleEvent,
  NAV_BAR_EVENTS,
  PROFILE_EVENTS,
  EVENT_ORIGIN,
} from 'services/analytics'
import { UserMenu as UserMenuIcon } from 'components/Icons'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'
import { HEADER_DROPDOWN_TRANSITIONS } from 'components/Popup/transitions'
import { useGetTradeLimitQuery } from 'containers/Cart/rtkApi'
import ContactFormModalWrapper from 'components/ContactForm/ContactFormModal/Wrapper'
import messages from '../UserMenu/messages'

import UserMenu from '../UserMenu'
import {
  UserIconContainer,
  UserIconCustomerNo,
  UserMenuContainer,
} from './styles'

const User = ({ userBtnStyles }) => {
  const { customerNo } = useSelector(userDataSelector)
  const { formatMessage } = useIntl()
  const [isContactFormOpen, setContactFormOpen] = useState(false)

  const { data: tradeLimit } = useGetTradeLimitQuery()

  const toggleDropdown = () => {
    trackSimpleEvent(NAV_BAR_EVENTS.USER_MENU_CLICKED)
  }

  const handleOpenContactForm = () => {
    setContactFormOpen(true)
    trackSimpleEvent(PROFILE_EVENTS.CONTACT_FORM_CLICKED, {
      origin: EVENT_ORIGIN.USER_MENU,
      button: formatMessage(messages.contactForm),
    })
  }

  return (
    <>
      <ContactFormModalWrapper
        isContactFormOpen={isContactFormOpen}
        onContactFormClose={() => setContactFormOpen(false)}
      />
      <Popover
        disableArrow
        placement="bottom-end"
        offsetValues={{
          main: 20,
        }}
      >
        <PopoverTrigger asChild>
          <UserIconContainer
            onClick={toggleDropdown}
            data-test-id="user-menu"
            userBtnStyles={userBtnStyles}
          >
            <UserMenuIcon />
            {customerNo && (
              <UserIconCustomerNo>{customerNo}</UserIconCustomerNo>
            )}
          </UserIconContainer>
        </PopoverTrigger>
        <PopoverContent withOverlay transitions={HEADER_DROPDOWN_TRANSITIONS}>
          <UserMenuContainer data-test-id="user-menu-dropdown">
            <UserMenu
              paymentColorName={tradeLimit}
              openContactForm={handleOpenContactForm}
            />
          </UserMenuContainer>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default User
