import { useGetInvoicesQuery } from 'containers/Invoices/List/rtkApi'
import { INVOICE_TYPE_IDS } from 'containers/Invoices/List/consts'
import { INVOICE_STATUS } from 'containers/Invoices/consts'

export const useGetInvoiceTotals = () => {
  const {
    data: { invoices = [], meta: { types: invoiceTypes = {} } = {} } = {},
  } = useGetInvoicesQuery({
    type: INVOICE_TYPE_IDS.TO_PAY,
    perPage: 200, // 200 - is max supported by middleware
  })

  const unpaidCount = invoiceTypes[INVOICE_TYPE_IDS.TO_PAY]?.count
  const overdueCount = invoices.filter(
    invoice => invoice.statusId === INVOICE_STATUS.NOT_PAID_EXPIRED,
  ).length
  const paymentDate = invoices[0]?.payment.deadlineDate
  const invoicesTotal = invoices.reduce(
    (acc, invoice) => acc + invoice.remainingAmount,
    0,
  )

  return {
    unpaidCount,
    overdueCount,
    paymentDate,
    invoicesTotal,
  }
}
