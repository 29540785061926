import React from 'react'

const RadioBtnActive = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="18" height="18" rx="9" fill="#229944" />
    <circle cx="12" cy="12" r="4" fill="white" />
  </svg>
)

export default RadioBtnActive
