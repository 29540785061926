import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetPayStatusQuery } from 'containers/Invoices/PayProcessing/rtkApi'
import { PAY_STATUS } from 'containers/Invoices/PayProcessing/consts'

import PayStatus from './PayStatus'

const PayProcessing = () => {
  const { paymentId } = useParams()
  const [isPolling, setIsPolling] = useState(true)

  const { data = {} } = useGetPayStatusQuery(
    { paymentId },
    {
      pollingInterval: isPolling ? 5000 : 0,
    },
  )

  useEffect(
    () => {
      if (data.status && data.status !== PAY_STATUS.PROCESSING) {
        setIsPolling(false)
      }
    },
    [data],
  )

  return <PayStatus variant={data.status} amount={data.amount} />
}
export default PayProcessing
