import React from 'react'

const TwoFA = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3967 2.70143L4 5.5V9.10534C4 11.6849 4.28025 14.311 5.44535 16.6124C6.70673 19.1041 8.69995 21.5 12 21.5C15.3001 21.5 17.2933 19.1041 18.5547 16.6124C19.7197 14.311 20 11.6849 20 9.10534V5.5L13.6033 2.70143C12.5812 2.25427 11.4188 2.25427 10.3967 2.70143Z"
      stroke="#A6B3C0"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <circle cx="12" cy="9" r="2" stroke="#A6B3C0" strokeWidth="2" />
    <path d="M12 11V15M12 18V15M12 15H15" stroke="#A6B3C0" strokeWidth="2" />
  </svg>
)

export default TwoFA
