import React from 'react'

import Article from './Article'
import Category from './Category'

const EntryContent = ({ selectedArticle, selectedCategory }) => {
  if (selectedArticle) {
    return <Article selectedArticle={selectedArticle} />
  }

  if (selectedCategory) {
    return <Category selectedCategory={selectedCategory} />
  }

  return null
}

export default EntryContent
