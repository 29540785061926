import React from 'react'
import { Box, Flex } from '@rebass/grid'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { formatPrice } from 'utils'
import { useOfMaxWidth } from 'hooks'

import { useGetTradeLimitQuery } from 'containers/Cart/rtkApi'
import { ORDER_PAY_BUTTON_COLOR } from 'containers/Orders/consts'
import { H4Primary } from 'components/Text'
import { useGetFinancesQuery } from 'containers/Invoices/rtkApi'
import { APP_BREAKPOINTS } from 'consts'
import messages from 'views/Cart/messages'
import { useGetInvoiceTotals } from 'views/Cart/SummaryFooter/hooks'

import { usePaymentDate } from './utils'
import { HeadingColumn, TailColumn, InfoRow } from '../components'
import PayInvoicesButton from './PayInvoicesButton'
import { InfoContainer, PaddedColumn, RowContainer } from './styles'

const { spacing } = theme

const FinanceRow = () => {
  const { formatMessage } = useIntl()
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.DESKTOP_START - 1)
  const isLowerThanMain = useOfMaxWidth(APP_BREAKPOINTS.TMP_MAIN - 1)
  const { data: tradeLimitData = {} } = useGetTradeLimitQuery()

  const {
    unpaidCount,
    overdueCount,
    paymentDate: invoicesPaymentDate,
    invoicesTotal,
  } = useGetInvoiceTotals()

  const isGreenClient =
    tradeLimitData.paymentColorId === ORDER_PAY_BUTTON_COLOR.GREEN
  const { data: invoicesSummary } = useGetFinancesQuery()
  const overdueAmount = invoicesSummary?.overdueAmount
  const overdueState = overdueAmount > 0 && !isGreenClient
  const paymentDate = usePaymentDate({
    overdueAmount,
    overdueState,
    invoicesPaymentDate,
  })

  const payBtnRendered = tradeLimitData.payButtonVisibleInCart && (
    <PayInvoicesButton {...{ invoicesTotal, tradeLimitData, isGreenClient }} />
  )
  const creditLimitRendered = (
    <InfoRow
      label="yourCreditLimit"
      value={formatPrice(tradeLimitData.tradeLimit)}
    />
  )
  const unpaidInvoicesRendered = (
    <Flex flexDirection="column">
      <InfoRow
        label="invoicesAmount"
        labelOpts={{
          invoicesCount: unpaidCount,
          invoices: formatMessage(messages.invoices, {
            number: unpaidCount,
          }),
        }}
        value={formatPrice(invoicesTotal)}
      />

      {overdueState && (
        <InfoRow
          label="overdueInvoices"
          labelOpts={{
            count: overdueCount,
            overduePlural: formatMessage(messages.overduePlural, {
              number: overdueCount,
            }),
          }}
          value={formatPrice(overdueAmount)}
        />
      )}

      <InfoRow
        label={overdueState ? 'afterDeadline' : 'paymentDate'}
        value={paymentDate}
      />
    </Flex>
  )

  return (
    <RowContainer {...{ isLowerThanMain }}>
      <HeadingColumn
        pullTop
        mb={isLowerThanMain ? spacing.md : 0}
        px={isMobile ? spacing.sm : 0}
      >
        <H4Primary>{formatMessage(messages.finances)}</H4Primary>
      </HeadingColumn>

      <InfoContainer {...{ isMobile }}>
        {isMobile ? (
          <>
            <Box p={spacing.sm}>
              {creditLimitRendered}
              {unpaidCount > 0 && unpaidInvoicesRendered}
            </Box>

            <Box mt={spacing.xs}>{payBtnRendered}</Box>
          </>
        ) : (
          <>
            <PaddedColumn
              borderedLeft={!isLowerThanMain}
              pl={isLowerThanMain ? 0 : spacing.base}
            >
              {creditLimitRendered}
            </PaddedColumn>

            <PaddedColumn borderedLeft>
              {unpaidCount > 0 && unpaidInvoicesRendered}
            </PaddedColumn>

            <TailColumn>{payBtnRendered}</TailColumn>
          </>
        )}
      </InfoContainer>
    </RowContainer>
  )
}

export default FinanceRow
