import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'

import { BreadCrumbList, BreadCrumb } from 'components/BreadCrumbs'
import { ROUTES } from 'consts'

import { useQueryFilters } from 'hooks'
import { generatePathWithSearch } from 'views/Products/Header/utils'
import messages from '../messages'
import { BreadCrumbsContainer } from './styles'

const ProductsBreadcrumbs = ({ children, isAllProducts }) => {
  const { formatMessage } = useIntl()
  const { searchQuery } = useQueryFilters()

  return (
    <BreadCrumbsContainer>
      <BreadCrumbList>
        <BreadCrumb to={generatePath(ROUTES.DASHBOARD)}>
          {formatMessage(messages.home)}
        </BreadCrumb>
        {!isAllProducts && (
          <BreadCrumb
            to={generatePathWithSearch(ROUTES.PRODUCTS, {
              search: searchQuery,
            })}
          >
            {formatMessage(messages.allProducts)}
          </BreadCrumb>
        )}

        {children}
      </BreadCrumbList>
    </BreadCrumbsContainer>
  )
}

export default ProductsBreadcrumbs
