import React, { useEffect, useMemo, useState } from 'react'
import { isEqual, isNil, isNumber, omit } from 'lodash/fp'
import { FormattedMessage, useIntl } from 'react-intl'
import usePrevious from 'use-previous'
import dayjs from 'dayjs'

import theme from 'theme'
import { getInvoiceExpiryDays } from 'views/Invoices/utils'
import { ClockV2 } from 'components/Icons'
import {
  INVOICE_STATUS,
  INVOICE_PAYMENT_STATUS,
  INVOICES_PER_PAGE_SIZE,
} from 'containers/Invoices/consts'
import { DATE_TIME_FORMATS } from 'utils/datetime'
import { formatPrice } from 'utils'
import { useQuery } from 'hooks'
import { INVOICE_TYPE_IDS } from 'containers/Invoices/List/consts'
import { DEFAULT_INVOICE_TYPE } from 'views/Invoices/consts'
import { useGetInvoicesQuery } from 'containers/Invoices/List/rtkApi'

import messages from './messages'

export const usePayLabel = invoice => {
  const { formatMessage } = useIntl()
  const isNotPaid = invoice.statusId === INVOICE_STATUS.NOT_PAID
  const isExpired = invoice.statusId === INVOICE_STATUS.NOT_PAID_EXPIRED
  const { payment } = invoice

  const processingMessage =
    payment.status === INVOICE_PAYMENT_STATUS.PROCESSING ? (
      <>
        <ClockV2 width={16} height={16} color={theme.colors.infoBlue2} />
        {formatMessage(messages.processing)}
      </>
    ) : null

  const [payLabel, payRemainingLabel] = useMemo(
    () => {
      const expiredDays = getInvoiceExpiryDays(payment.deadlineDate)

      if (
        invoice.remainingAmount > 0 &&
        invoice.amount !== invoice.remainingAmount
      ) {
        if (isExpired) {
          return [
            <FormattedMessage
              {...messages.remainingDiff}
              values={{
                amount: formatPrice(invoice.amount - invoice.remainingAmount),
              }}
            />,
            <>
              {`${invoice.remainingAmountLabel} `}
              {formatMessage(
                expiredDays === 1
                  ? messages.paySelectExpiredDay
                  : messages.paySelectExpiredDays,
                { days: expiredDays },
              )}
            </>,
          ]
        }

        if (isNotPaid) {
          return [
            <FormattedMessage
              {...messages.remainingDiff}
              values={{
                amount: formatPrice(invoice.amount - invoice.remainingAmount),
              }}
            />,
            <FormattedMessage
              {...messages.payTill}
              values={{
                paymentDeadlineDateLabel: `${invoice.remainingAmountLabel} ${
                  payment.deadlineDateLabel
                }`,
                date: dayjs(payment.deadlineDate).format(
                  DATE_TIME_FORMATS.FULL_DATE,
                ),
              }}
            />,
          ]
        }

        return [
          <FormattedMessage
            {...messages.remainingDiff}
            values={{
              amount: formatPrice(invoice.amount - invoice.remainingAmount),
            }}
          />,
          invoice.remainingAmountLabel,
        ]
      }

      if (isExpired) {
        return [
          <>
            {`${invoice.amountLabel} `}
            {formatMessage(
              expiredDays === 1
                ? messages.paySelectExpiredDay
                : messages.paySelectExpiredDays,
              { days: expiredDays },
            )}
          </>,
          invoice.remainingAmountLabel,
        ]
      }

      if (isNotPaid) {
        return [
          <FormattedMessage
            {...messages.payTill}
            values={{
              paymentDeadlineDateLabel: `${invoice.amountLabel} ${
                payment.deadlineDateLabel
              }`,
              date: dayjs(payment.deadlineDate).format(
                DATE_TIME_FORMATS.FULL_DATE,
              ),
            }}
          />,
          invoice.remainingAmountLabel,
        ]
      }

      return [invoice.amountLabel, invoice.remainingAmountLabel]
    },
    [isExpired],
  )
  return [processingMessage || payLabel, payRemainingLabel]
}

export const useGetInvoicesDataByQuery = () => {
  const { page: queryPage, type = DEFAULT_INVOICE_TYPE, preselectStatus } =
    useQuery() || {}

  // TODO: update query parsing/passing to always return array
  const preselectStatuses = useMemo(
    () => {
      const result = isNil(preselectStatus) ? [] : preselectStatus
      return Array.isArray(result) ? result : [result]
    },
    [preselectStatus],
  )

  const isPaginated =
    !preselectStatuses.length || type !== INVOICE_TYPE_IDS.TO_PAY

  const page = isNumber(queryPage) ? queryPage : 1
  const perPage = isPaginated ? INVOICES_PER_PAGE_SIZE : 200 // 200 - is max supported by middleware

  const {
    data: { invoices = [], meta: { pagination, types = {} } = {} } = {},
    isFetching,
  } = useGetInvoicesQuery({
    page,
    type,
    perPage,
  })

  return {
    page,
    isPaginated,
    type,
    perPage,
    preselectStatus,
    preselectStatuses,
    invoices,
    pagination,
    isFetching,
    types,
  }
}

export const useSelectedInvoicesToPay = ({ invoices, preselectStatuses }) => {
  const [selectedInvoices, setSelectedInvoices] = useState({})
  const prevInvoices = usePrevious(invoices)

  useEffect(
    () => {
      // workaround to only set to preselected after first fetch
      if (
        invoices?.length &&
        !prevInvoices?.length &&
        preselectStatuses.length
      ) {
        const newSelected = invoices
          .filter(
            ({ statusId, payment: { status } }) =>
              status !== INVOICE_PAYMENT_STATUS.PROCESSING &&
              preselectStatuses.indexOf(statusId) > -1,
          )
          .reduce((acc, invoice) => ({ ...acc, [invoice.id]: invoice }), {})

        setSelectedInvoices(newSelected)
      } else if (!isEqual(prevInvoices, invoices)) {
        const newSelected = Object.values(selectedInvoices).reduce(
          (acc, invoice) => {
            const foundInvoice = invoices.find(i => i.id === invoice.id)
            if (
              foundInvoice &&
              foundInvoice.payment.status !== INVOICE_PAYMENT_STATUS.PROCESSING
            ) {
              return {
                ...acc,
                [foundInvoice.id]: foundInvoice,
              }
            }

            return acc
          },
          {},
        )

        setSelectedInvoices(newSelected)
      }
    },
    [preselectStatuses, invoices, prevInvoices],
  )

  const toggleInvoice = invoice => {
    if (selectedInvoices[invoice.id]) {
      setSelectedInvoices(omit([invoice.id], selectedInvoices))
    } else {
      setSelectedInvoices({ ...selectedInvoices, [invoice.id]: invoice })
    }
  }

  const clearSelection = () => {
    setSelectedInvoices({})
  }

  const selectedTotal = Object.values(selectedInvoices).reduce(
    (acc, invoice) => acc + invoice.remainingAmount,
    0,
  )

  return {
    selectedInvoices,
    selectedTotal,
    toggleInvoice,
    clearSelection,
  }
}
