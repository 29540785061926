export const parseDocumentEntries = response => {
  const entries = response.includes.Entry
  const assets = response.includes.Asset

  return response.items[0].fields.documents.map(document => {
    const entry = entries.find(item => item.sys.id === document.sys.id)
    return {
      id: entry.sys.id,
      title: entry.fields.displayName,
      currentFileUrl: assets.find(
        asset => entry.fields.currentFile.sys.id === asset.sys.id,
      ).fields.file.url,
      previousVersionFileUrl: assets.find(
        asset => entry.fields.previousVersionFile?.sys.id === asset.sys.id,
      )?.fields.file.url,
    }
  })
}
