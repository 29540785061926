import styled from 'styled-components'

import theme from 'theme'

import Notification from 'components/Notification'
import { APP_BREAKPOINTS } from 'consts'
import { MOBILE_HEIGHT, DESKTOP_HEIGHT } from './Header/styles'

export const PageContent = styled.main`
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex: 1;
  margin: 0 auto;
`

export const Separator = styled.div`
  background: ${theme.colors.borderFaded};
  height: 1px;
  margin: ${theme.spacing.sm};
`

export const FullWidthSeparator = styled(Separator)`
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  margin-top: ${theme.spacing.sm};
`

export const HeaderNotification = styled(Notification)`
  position: fixed;
  z-index: ${theme.zIndex.modalOverlay +
    2}; // display over potential modal overlay
  top: ${p => (p.isMobile ? MOBILE_HEIGHT + 8 : DESKTOP_HEIGHT + 16)}px;
  left: 0;
  right: 0;
  margin-left: ${theme.spacing.sm};
  margin-right: ${theme.spacing.sm};
  width: auto;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 492px;
  }
`
