import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ItemsTable from 'components/ItemsTable'

import { InvoiceListWrapper } from 'views/Invoices/List/styles'
import { trackSelectedInvoice } from 'services/analytics'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { isInvoicePayable } from '../../../utils'
import InvoiceListItem from '../InvoiceListItem'
import { Wrapper } from './styles'

const InvoicesList = ({
  isPaginated,
  invoices,
  pagination,
  onPageChange,
  isFetching,
  selectedInvoicesMap,
  toggleInvoice,
}) => {
  const userData = useSelector(userDataSelector)

  if (isFetching) return null

  const invoiceItems = invoices.map((invoice, index) => (
    <InvoiceListItem
      {...{ invoice }}
      key={invoice.id}
      onToggleSelect={() => {
        if (!selectedInvoicesMap[invoice.id]) {
          trackSelectedInvoice({
            storeId: userData.storeId,
            invoiceId: invoice.id,
          })
        }
        toggleInvoice(invoice)
      }}
      isSelectable={isInvoicePayable(invoice)}
      isSelected={!!selectedInvoicesMap[invoice.id]}
      bordered={index !== invoices.length - 1}
    />
  ))

  if (isPaginated) {
    return (
      <ItemsTable {...{ onPageChange, pagination }}>
        <InvoiceListWrapper>{invoiceItems}</InvoiceListWrapper>
      </ItemsTable>
    )
  }

  return <Wrapper>{invoiceItems}</Wrapper>
}

InvoicesList.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})),
  pagination: PropTypes.shape({}),
  onPageChange: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default InvoicesList
