import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { PROFILE_ROUTES } from 'containers/Profile/consts'
import { notifyFailure, notifySuccess } from 'components/Product/utils'
import {
  RTK_SETTINGS_TAG,
  useInitToggle2FAMutation,
  useToggle2FAMutation,
} from 'containers/Settings/rtkApi'
import { rtkApi } from 'services/api/rtkApi'

import messages from './messages'

export const useToggle2FAHandlers = ({
  // current state, so if trying to enable, then false
  twoFactorEnabled,
  onRequestOTPSuccess,
  onAlreadySet,
  onInvalidOTP,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const [requestOTP, requestOTPQuery] = useInitToggle2FAMutation()
  const [toggle2FA, toggle2FAQuery] = useToggle2FAMutation()

  const handleRequestOTP = async () => {
    try {
      const data = await requestOTP({ twoFactorEnabled }).unwrap()
      onRequestOTPSuccess(data)
    } catch (e) {
      if (e.status === 422) {
        dispatch(rtkApi.util.invalidateTags([RTK_SETTINGS_TAG]))
        dispatch(notifyFailure(formatMessage(messages.error)))
        navigate(PROFILE_ROUTES.PROFILE)
        onAlreadySet?.()
      } else {
        dispatch(notifyFailure(formatMessage(messages.tryAgain)))
      }
    }
  }

  const handleSubmitOTP = async ({ otp }) => {
    try {
      await toggle2FA({
        twoFactorEnabled: !twoFactorEnabled,
        otp,
      }).unwrap()
      dispatch(
        notifySuccess(
          !twoFactorEnabled
            ? messages.activate2FASuccess
            : messages.deactivate2FASuccess,
        ),
      )
      navigate(PROFILE_ROUTES.PROFILE)
    } catch (e) {
      if (e.status === 403) {
        onInvalidOTP()
      } else {
        dispatch(notifyFailure(messages.tryAgain))
      }
    }
  }

  return {
    handleRequestOTP,
    handleSubmitOTP,
    isRequestOTPLoading: requestOTPQuery.isLoading,
    isToggle2FALoading: toggle2FAQuery.isLoading,
  }
}
