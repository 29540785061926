import React from 'react'

import { APP_BREAKPOINTS } from 'consts'
import Breadcrumbs from 'views/Products/Header'
import Carousel from 'components/Carousel'
import ImageSliderControls from 'views/ProductDetails/ProductImageSection/ImageSliderControls'
import ProductImage, {
  PRODUCT_IMAGE_SIZES,
} from 'components/Product/ProductImage'

import {
  ProductImgContainer,
  ProductImageWrapper,
  ProductImageScrollContainer,
  ProductImageCarouselWrapper,
} from 'views/ProductDetails/ProductImageSection/styles'
import { PDP_EVENTS, trackSimpleEvent } from 'services/analytics'
import { getBreadcrumbsParams } from '../utils'

const DESKTOP_BREAKPOINT = `(min-width: ${APP_BREAKPOINTS.DESKTOP_START}px)`

const ProductImageSection = ({ product, isProductDisabled }) => {
  const productParams = getBreadcrumbsParams(product)

  const handleSlideChange = index => {
    trackSimpleEvent(PDP_EVENTS.PHOTO_CLICKED, {
      position: index + 1,
      photo_count: product.images.length > 3 ? 3 : product.images.length,
    })
  }

  return (
    <ProductImgContainer>
      <Breadcrumbs productParams={productParams} />
      <Carousel
        CarouselWrapper={ProductImageCarouselWrapper}
        ScrollContainer={ProductImageScrollContainer}
        customControls={{
          component: ImageSliderControls,
          props: { product, isProductDisabled },
        }}
        carouselConfig={{
          breakpoints: {
            [DESKTOP_BREAKPOINT]: {
              watchDrag: false,
              axis: 'y',
            },
          },
        }}
        onSlideChange={handleSlideChange}
      >
        {product.images.length ? (
          product.images.slice(0, 3).map(img => (
            <ProductImageWrapper key={`carousel-${img.urlBig}`}>
              <ProductImage
                outOfStock={isProductDisabled}
                product={product}
                imageSrc={img.urlBig}
                size={PRODUCT_IMAGE_SIZES.L}
              />
            </ProductImageWrapper>
          ))
        ) : (
          // display placeholder as fallback
          <ProductImageWrapper>
            <ProductImage
              outOfStock={isProductDisabled}
              product={product}
              size={PRODUCT_IMAGE_SIZES.L}
            />
          </ProductImageWrapper>
        )}
      </Carousel>
    </ProductImgContainer>
  )
}

export default ProductImageSection
