import React, { useMemo, useState } from 'react'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { CONTACT_TYPES } from 'containers/UserInfo/consts'
import { H4v2, BaseTextV2 } from 'components/Text'
import {
  User as UserIcon,
  Mail as MailIcon,
  Phone as PhoneIcon,
} from 'components/Icons'
import { trackSimpleEvent, PROFILE_EVENTS } from 'services/analytics'
import { getValueByType } from './utils'
import DropdownArrow from '../../DropdownArrow'

import {
  ContactContainer,
  ContactTextContainer,
  ContactLink,
  ContactTitle,
  ContactDetails,
  ContactTitleContainer,
  DropdownWrapper,
  ContactTitleAndIcons,
  ContactTitleIconContainer,
} from './styles'

const ContactRow = ({ contact, title }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const name = getValueByType(contact, CONTACT_TYPES.NAME)
  const email = getValueByType(contact, CONTACT_TYPES.EMAIL)
  const phone = getValueByType(contact, CONTACT_TYPES.PHONE)
  const contactDetails = useMemo(
    () =>
      contact
        .filter(item => item.value && item.value !== name)
        .map(item => item.value),
    [contact],
  )

  const handleClick = () => {
    setIsOpen(state => !state)
    trackSimpleEvent(PROFILE_EVENTS.CONTACT_CLICKED)
  }

  return (
    <ContactContainer data-test-id="contact-row">
      <DropdownWrapper>
        <ContactTitleAndIcons
          onClick={handleClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <ContactTitleContainer>
            <ContactTitleIconContainer>
              {isHovered ? (
                <DropdownArrow isOpen={isOpen} />
              ) : (
                <UserIcon color={theme.colors.gray5} />
              )}
            </ContactTitleIconContainer>
            <ContactTextContainer>
              <H4v2>{name}</H4v2>
              <ContactTitle>{title}</ContactTitle>
            </ContactTextContainer>
          </ContactTitleContainer>
          <Flex>
            {email && (
              <ContactLink
                href={`mailto:${email}`}
                data-test-id="profile-email-link"
                onClick={e => e.stopPropagation()}
              >
                <MailIcon />
              </ContactLink>
            )}
            {phone && (
              <ContactLink
                href={`tel:${phone}`}
                data-test-id="profile-tel-link"
                onClick={e => e.stopPropagation()}
              >
                <PhoneIcon />
              </ContactLink>
            )}
          </Flex>
        </ContactTitleAndIcons>
        {isOpen && (
          <ContactDetails>
            {contactDetails.map((value, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <BaseTextV2 isSmall={{ fontSize: true }} key={index}>
                {value}
              </BaseTextV2>
            ))}
          </ContactDetails>
        )}
      </DropdownWrapper>
    </ContactContainer>
  )
}

export default ContactRow
