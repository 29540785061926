import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import useDeadlineOrderDates from 'hooks/useDeadlineOrderDates'
import {
  TruckSpeed as TruckIcon,
  Box as BoxIcon,
  TruckClosed as TruckClosedIcon,
  InfoCircle as InfoIcon,
  CalendarV3 as CalendarIcon,
  ClockV2,
} from 'components/Icons'

import { getUnavailableMsg } from 'components/Product/Availability/utils'
import { UNAVAILABILITY_TYPES } from 'components/Product/Availability/consts'
import messages from '../../messages'

export const useAvailabilityConfig = ({
  product,
  unitData,
  isSoonAvailable,
  productDeliveryOptions,
  closestDeliveryDate: { deadlineOrderDate, deliveryDate },
}) => {
  const { formatMessage } = useIntl()

  const {
    withdrawingSoon,
    closestDeliveryTime,
    deadlineOrderTime,
    receiptDate,
    nonStock,
  } = product

  const [deadlineDateFormatted, deliveryDateFormatted] = useDeadlineOrderDates({
    deadlineOrderDate,
    deliveryDate,
  })
  const unavailabilityMsg = useMemo(
    () =>
      getUnavailableMsg({
        closestDeliveryTime,
        withdrawingSoon,
        deadlineOrderTime,
        isSoonAvailable,
        receiptDate,
        nonStock,
        productDeliveryOptions,
        unitOfMeasureObj: unitData,
        hasLongUnavailableMsg: true,
        hasLongNonStockMsg: true,
      }),
    [
      closestDeliveryTime,
      deadlineOrderTime,
      isSoonAvailable,
      receiptDate,
      nonStock,
      productDeliveryOptions,
      unitData,
    ],
  )

  // unavailability variants
  if (unavailabilityMsg.message) {
    let IconComponent = BoxIcon
    let type

    if (unavailabilityMsg.type === UNAVAILABILITY_TYPES.WITHDRAWING_SOON) {
      IconComponent = InfoIcon
    }

    if (unavailabilityMsg.type === UNAVAILABILITY_TYPES.AVAILABLE_SOON) {
      IconComponent = ClockV2
    }

    if (unavailabilityMsg.type === UNAVAILABILITY_TYPES.LIMITED_STOCK) {
      IconComponent = TruckClosedIcon
    }

    if (unavailabilityMsg.type === UNAVAILABILITY_TYPES.NON_STOCK) {
      IconComponent = CalendarIcon
      type = UNAVAILABILITY_TYPES.NON_STOCK
    }

    return { message: unavailabilityMsg.message, IconComponent, type }
  }

  // default available variant
  return {
    message: formatMessage(messages.deadlineOrderDate, {
      deadlineDate: deadlineDateFormatted,
      deliveryDate: deliveryDateFormatted,
    }),
    IconComponent: TruckIcon,
  }
}
