import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { TEMPLATE_ROW_MOBILE_MAX_WIDTH } from 'components/Product/consts'
import { CatalogAddProductsButton } from 'components/Product/ProductButtons/styles'
import { BaseTextV2, H1v2 } from 'components/Text'
import { GreyWrapper } from 'components/Button/styles'
import { APP_BREAKPOINTS } from 'consts'
import { STICKY_ELEMENTS_OFFSET } from 'views/Templates/styles'
import { TEMPLATE_LIST_WIDTH } from '../TemplatesList/styles'

export const TemplateNameWrapper = styled(Flex)`
  overflow-wrap: anywhere;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  margin-left: 32px;

  span {
    flex-grow: 1;
  }

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    margin-left: 0;
    padding: 0 16px;
  }
`

export const TemplateName = styled(H1v2)`
  ${props =>
    !props.editable &&
    `
    color: ${theme.colors.secondary};
    display: flex;
    gap: 16px;
    align-items: center;
  `};

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    font-size: 22px;
    line-height: 30px;
  }
`

export const TemplateDetailsWrapper = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  padding: 16px;

  @media (min-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    padding: 0 32px 32px 32px;
    width: calc(100% - ${TEMPLATE_LIST_WIDTH});
  }
`

export const TemplateItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  list-style: none;

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    gap: 16px;
  }
`

export const TemplateProductListWrapper = styled(Flex)`
  flex-direction: column;
  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    // sticky-offset
    margin-top: calc(-100vh + ${STICKY_ELEMENTS_OFFSET}px);
  }
`

export const TemplateNotAvailableContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0;
  list-style: none;
  padding-bottom: 24px;
  border-bottom: 1px solid ${theme.colors.borderFaded};
  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    gap: 16px;
    margin: 0;
    margin-bottom: 16px;
    padding-bottom: 16px;
  }
`

export const NoLongerActiveContainer = styled(Flex)`
  gap: 8px;
`

export const NoLongerActive = styled(BaseTextV2)`
  color: ${theme.colors.redLight};
  font-weight: 500;
`

export const NoLongerActiveCount = styled(BaseTextV2).attrs({
  isSmall: { fontSize: true },
})`
  color: ${theme.colors.redLight};
  line-height: 22px;
  padding-top: 2px;
`

export const NoLongerActiveRemainingContainer = styled(NoLongerActiveContainer)`
  margin: 24px 0;
  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    margin: 0;
  }
`

export const NoLongerActiveRemaining = styled(NoLongerActive)`
  color: ${theme.colors.primary};
`

export const NoLongerActiveRemainingCount = styled(NoLongerActiveCount)`
  color: ${theme.colors.lightGrey4};
`

export const TemplateItem = styled.li`
  box-sizing: content-box;
`

export const TemplateAddProductsButton = styled(CatalogAddProductsButton)`
  width: auto;
  > svg {
    margin-right: 16px;
  }

  :disabled {
    cursor: auto;
    background: ${theme.colors.lightGrey7};
    circle {
      fill: ${theme.colors.gray7};
    }
    path {
      stroke: ${theme.colors.gray7};
    }
    span {
      color: ${theme.colors.gray7};
    }
    :hover {
      background: ${theme.colors.lightGrey7};
    }
  }
`

export const TemplateAddProductsText = styled(BaseTextV2)`
  font-weight: 500;
  color: ${theme.colors.white};
`

export const EmptyListContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 96px;
  margin-bottom: 122px;
  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    ${p =>
      p.withFilters &&
      `margin-top: calc(${STICKY_ELEMENTS_OFFSET}px - 100vh + 96px);`};
  }
`

export const EmptyPageHeading = styled(BaseTextV2)`
  color: ${theme.colors.lightGrey4};
`

export const EmptyPageText = styled(BaseTextV2)`
  cursor: pointer;
`

export const HeaderContainer = styled(Flex)`
  justify-content: space-between;
  margin-left: -32px;
  border-bottom: 1px solid ${theme.colors.borderFaded};

  @media screen and (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 16px;
    margin: 0 -16px;
    ${TemplateAddProductsButton} {
      width: 100%;
    }
  }
`

export const TemplateHeaderActions = styled(Flex)`
  gap: 8px;
  height: fit-content;
  @media screen and (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    padding: 0 16px;
  }
`

export const RemoveTemplateBtn = styled(GreyWrapper)`
  padding: 0;
  min-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  align-self: center;

  :hover {
    background-color: ${theme.colors.lightGrey6};
  }
`
