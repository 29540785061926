import theme from 'theme'

export const INVOICES_PER_PAGE_SIZE = 24

export const INVOICE_STATUS = {
  NOT_PAID: 0,
  NOT_PAID_EXPIRED: 1,
  PAID: 2,
  PAYMENT: 3,
  OVERPAID: 4,
}

export const INVOICE_PAYMENT_STATUS = {
  PROCESSING: 'processing',
}

export const API_INVOICE_STATUS_COLOR = {
  GREEN: 0,
  YELLOW: 1,
  ORANGE: 2,
  RED: 3,
  NAVY: 4,
  GREY: 5,
}

export const INVOICE_STATUS_COLOR = {
  [API_INVOICE_STATUS_COLOR.GREEN]: theme.colors.secondary,
  [API_INVOICE_STATUS_COLOR.YELLOW]: theme.colors.warning,
  [API_INVOICE_STATUS_COLOR.ORANGE]: theme.colors.warningHighlight,
  [API_INVOICE_STATUS_COLOR.RED]: theme.colors.red,
  [API_INVOICE_STATUS_COLOR.NAVY]: theme.colors.primary,
  [API_INVOICE_STATUS_COLOR.GREY]: theme.colors.gray4,
}
