import { rtkApi } from 'services/api/rtkApi'

export const RTK_INCOMING_ORDERS_TAG = 'IncomingOrders'
export const RTK_TRADE_LIMIT_TAG = 'TradeLimit'

const cartApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_INCOMING_ORDERS_TAG, RTK_TRADE_LIMIT_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getIncomingOrders: build.query({
        query: () => ({
          url: 'me/incoming_orders',
        }),
        transformResponse: res => res?.incomingOrders || [],
        providesTags: [RTK_INCOMING_ORDERS_TAG],
      }),
      getTradeLimit: build.query({
        query: () => ({
          url: 'me/trade_limit',
        }),
        providesTags: [RTK_TRADE_LIMIT_TAG],
      }),
      reportCartStatusChange: build.mutation({
        query: ({ value }) => ({
          url: 'me/cart/changed_status',
          method: 'PUT',
          data: { value },
        }),
      }),
    }),
  })

export const {
  useGetIncomingOrdersQuery,
  useReportCartStatusChangeMutation,
  useGetTradeLimitQuery,
} = cartApi

export default cartApi
