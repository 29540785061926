import { rtkApi } from 'services/api/rtkApi'

export const RTK_FINANCES_TAG = 'Finances'

const financesApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_FINANCES_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getFinances: build.query({
        query: () => ({
          url: '/me/finances',
        }),
        providesTags: [RTK_FINANCES_TAG],
      }),
    }),
  })

export const { useGetFinancesQuery } = financesApi
