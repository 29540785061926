import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { isEmpty, intersectionWith } from 'lodash'
import { compose, isEqual } from 'lodash/fp'
import MediaQuery from 'react-responsive'
import { useDispatch, useSelector } from 'react-redux'

import { useUpdateStorageMutation } from 'services/api/rtkApi'
import {
  useGetStorageValue,
  CONFIG_SCHEMA_KEYS,
  CONFIG_API_PATHS_MAP,
  useFetchStorage,
} from 'containers/Storage'
import { APP_BREAKPOINTS } from 'consts'
import { getScrollbarWidth, FormattedRichMessage } from 'utils'
import { withSpinner } from 'hocs'
import withModal from 'hocs/withModal'
import { openModal } from 'containers/App/actions/modal'
import {
  orderItemsErrorSelector,
  orderErrorSelector,
} from 'containers/Orders/selectors'
import { orderActions } from 'containers/Orders/actions'
import {
  PROMO_GROUP_REDUCER_NAME,
  PROMOS_REDUCER_NAME,
} from 'containers/Promotions/consts'
import { CART_INFO_REDUCER_NAME } from 'containers/Cart/consts'
import {
  ORDER_INFO_REDUCER_NAME,
  ORDER_DETAILS_REDUCER_NAME,
  ORDER_ERRORS,
} from 'containers/Orders/consts'
import {
  MODAL_REMOVE_PRODUCTS,
  DELIVERY_EXPIRED_MODAL,
} from 'containers/App/modalTypes'
import { trackPageView, SCREENS } from 'services/analytics'
import { retailTrackCartView } from 'services/analytics/retail'
import { HintModal } from 'components'
import {
  selectCartInfoData,
  cartDataSelector,
  allCartItemsSelector,
  cartDeliveriesSelector,
  cartPromosTotalPointsSelector,
} from 'containers/Cart/selectors'
import cartActionsGroup from 'containers/Cart/actions'
import { useGetIncomingOrdersQuery } from 'containers/Cart/rtkApi'
import { getMergedDeliveries, getDeliveriesByDate } from 'containers/Cart/utils'
import DeliveryExpiredModal, {
  DeliveryExpiredModalRoot,
} from './DeliveryExpiredModal'

import RemoveProductsModal from './RemoveProductsModal'
import messages from './messages'
import {
  CartWrapper,
  CartContent,
  ProductsColumn,
  MainWrapper,
  GreyWrapper,
} from './styledComponents'
import CartProducts from './CartProducts'
import EmptyCart from './EmptyCart'
import ErrorContainer from './ErrorContainer'
import DeliveryDatesList from './DeliveryDates/DeliveryDatesList'
import DeliveryDatesStack from './DeliveryDates/DeliveryDatesStack'
import SummaryFooter from './SummaryFooter'
import PromotionsSection from './Promotions'
import ActionsHeader from './ActionsHeader'
import { hintModalConfig } from './hintModalConfig'

class Cart extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const {
      promosTotalPoints,
      getCart,
      orderError,
      allCartItems,
      orderItemsError,
      clearOrder,
    } = this.props

    if (!prevProps.orderError && orderError) {
      getCart()
    } else if (
      !isEmpty(prevProps.promosTotalPoints) &&
      !isEqual(prevProps.promosTotalPoints, promosTotalPoints)
    ) {
      const diffKeys = Object.keys(prevProps.promosTotalPoints).filter(
        k => prevProps.promosTotalPoints[k] > promosTotalPoints[k],
      )

      if (diffKeys.length) {
        getCart()
      }
    }

    // TODO check if below is still necessary
    if (
      prevProps.allCartItems.length !== allCartItems.length &&
      !isEmpty(orderItemsError)
    ) {
      const errorIds = orderItemsError.reduce((total, curr) => {
        if (curr.errorMessage || curr.errorId) {
          total.push(curr.productId)
        }
        return total
      }, [])

      const cartItemIds = allCartItems.map(item => item.productId)

      if (intersectionWith(errorIds, cartItemIds).length === 0) {
        clearOrder()
      }
    }

    if (prevProps.orderError !== orderError) {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  componentWillUnmount() {
    const { orderError, clearOrder } = this.props
    if (orderError && orderError !== ORDER_ERRORS.POSITIONS_ERROR) {
      clearOrder()
    }
  }

  render() {
    const {
      cartData,
      formatMessage,
      orderError,
      fetching,
      deliveries,
      dataGrouppedByDate,
      children,
      aggregatedCartData,
      openModal: hocOpenModal,
    } = this.props
    const scrollbarWidth = getScrollbarWidth()

    const hideDeliveries =
      dataGrouppedByDate.length === 1 &&
      dataGrouppedByDate[0].routes.length === 1
    const timelineTitle = deliveries.length ? (
      <FormattedRichMessage
        {...messages.receiveDeliveries}
        values={{
          deliveriesCount: deliveries.length,
          deliveriesLabel: formatMessage(messages.headerDeliveriesCount, {
            number: deliveries.length,
          }),
        }}
      />
    ) : (
      ''
    )
    const datesListProps = {
      dataGrouppedByDate,
      hideDeliveries,
      timelineTitle,
    }

    const actionsHeader = (
      <ActionsHeader
        hideActionButtons={!cartData.length && !fetching}
        onClearCart={() => {
          hocOpenModal(
            {
              heading: formatMessage(messages.modalHeader),
            },
            MODAL_REMOVE_PRODUCTS,
          )
        }}
      />
    )

    if (!cartData.length && !fetching) {
      return (
        <MainWrapper>
          {actionsHeader}

          <CartWrapper>
            <EmptyCart />
          </CartWrapper>
        </MainWrapper>
      )
    }

    return (
      <MainWrapper>
        <ErrorContainer {...{ orderError }} />

        <GreyWrapper {...{ scrollbarWidth }}>
          {actionsHeader}

          <MediaQuery minWidth={APP_BREAKPOINTS.TABLET_START}>
            <DeliveryDatesList {...datesListProps} />
          </MediaQuery>
        </GreyWrapper>

        <CartWrapper>
          <CartContent>
            <MediaQuery maxWidth={APP_BREAKPOINTS.TABLET_START - 1}>
              <DeliveryDatesStack {...datesListProps} />
            </MediaQuery>

            <ProductsColumn>
              <CartProducts aggregatedCartData={aggregatedCartData} />
            </ProductsColumn>
          </CartContent>

          <PromotionsSection />

          <SummaryFooter
            scrollbarWidth={scrollbarWidth}
            aggregatedCartData={aggregatedCartData}
          />
        </CartWrapper>

        {children}
      </MainWrapper>
    )
  }
}

const CartView = props => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const cartInfoData = useSelector(selectCartInfoData)
  const cartData = useSelector(cartDataSelector)
  const allCartItems = useSelector(allCartItemsSelector)
  const deliveries = useSelector(cartDeliveriesSelector)
  const promosTotalPoints = useSelector(cartPromosTotalPointsSelector)
  const orderItemsError = useSelector(orderItemsErrorSelector)
  const orderError = useSelector(orderErrorSelector)

  // initializing and migrating storage
  useFetchStorage()

  const showCartGuideIntro = useGetStorageValue(
    CONFIG_SCHEMA_KEYS.CART_GUIDE.INTRO,
  )
  const [hintModalIndex, setHintModalIndex] = useState(
    showCartGuideIntro ? 0 : -1,
  )
  const [updateStorage] = useUpdateStorageMutation()

  useEffect(
    () => {
      if (showCartGuideIntro) {
        setHintModalIndex(0)
      }
    },
    [showCartGuideIntro],
  )

  const getCart = useCallback(
    () => {
      dispatch(cartActionsGroup.delta())
    },
    [dispatch],
  )

  const clearOrder = useCallback(() => {
    dispatch(orderActions.clear())
  }, [])

  useEffect(
    () => {
      if (cartInfoData?.anyAutomaticallyChangedDelivery) {
        dispatch(openModal(DELIVERY_EXPIRED_MODAL, { hideHeader: true }))
      }
    },
    [dispatch, cartInfoData?.anyAutomaticallyChangedDelivery],
  )

  useEffect(() => {
    trackPageView(SCREENS.CART)
    retailTrackCartView()
  }, [])

  const { data: incomingOrders } = useGetIncomingOrdersQuery()

  const [aggregatedCartData, aggregatedCartDataGroupped] = useMemo(
    () => [
      getMergedDeliveries({ cartData, incomingOrders }),
      getDeliveriesByDate({ cartData, incomingOrders }),
    ],
    [cartData, incomingOrders],
  )

  return (
    <Cart
      {...props}
      formatMessage={formatMessage}
      cartData={cartData}
      allCartItems={allCartItems}
      deliveries={deliveries}
      dataGrouppedByDate={aggregatedCartDataGroupped}
      promosTotalPoints={promosTotalPoints}
      orderItemsError={orderItemsError}
      orderError={orderError}
      aggregatedCartData={aggregatedCartData}
      getCart={getCart}
      clearOrder={clearOrder}
    >
      {hintModalIndex > -1 && (
        <HintModal
          {...hintModalConfig[hintModalIndex]}
          onClose={() => {
            setHintModalIndex(-1)
            updateStorage({
              path: CONFIG_API_PATHS_MAP.CART_GUIDE.INTRO,
              value: false,
            })
          }}
          nextClickHandler={() => setHintModalIndex(hintModalIndex + 1)}
        />
      )}
    </Cart>
  )
}

export default compose(
  withSpinner([
    CART_INFO_REDUCER_NAME,
    [ORDER_INFO_REDUCER_NAME, ORDER_DETAILS_REDUCER_NAME],
    [PROMOS_REDUCER_NAME, PROMO_GROUP_REDUCER_NAME],
  ]),
  withModal(RemoveProductsModal, MODAL_REMOVE_PRODUCTS),
  withModal(DeliveryExpiredModal, DELIVERY_EXPIRED_MODAL, {
    rootComponent: DeliveryExpiredModalRoot,
  }),
)(CartView)
