import {
  LogisticIcon,
  DeliveryIcon,
  AlertIcon,
  AlcoholIcon,
} from 'components/Icons/Notifications'
import theme from 'theme'
import messages from './NotificationCenterModal/messages'

const FEED_MESSAGE_STYLING_TYPE = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
}

export const FEED_MESSAGE_TYPES = {
  logisticLimitNotSet: 'logistic-limit-not-set',
  productBackForStore: 'product-back-for-store-notifications',
  productWithdrawn: 'product-withdrawn-notifications',
  productAddedToCatalog: 'product-added-to-catalog-notifications',
  alcoholLicenseRenewal: 'alcohol-license-date-finish',
  alcoholLicenseFee: 'alcohol-license-payment-date-finish',
}

export const FEED_MESSAGE_STYLING_CONFIG = {
  [FEED_MESSAGE_STYLING_TYPE.POSITIVE]: {
    bgColor: theme.colors.greenLight5,
    iconColors: {
      primary: theme.colors.secondary,
      secondary: '#A2E2B5',
    },
  },
  [FEED_MESSAGE_STYLING_TYPE.NEGATIVE]: {
    bgColor: '#FF4C001C',
    iconColors: {
      primary: theme.colors.redLight,
      secondary: '#E9B9B6',
    },
  },
}

export const FEED_MESSAGE_MAP = {
  [FEED_MESSAGE_TYPES.logisticLimitNotSet]: {
    titleKey: messages.unmetLogisticLimitTitle,
    IconComponent: LogisticIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.NEGATIVE,
  },
  [FEED_MESSAGE_TYPES.productBackForStore]: {
    titleKey: messages.productBackForStoreTitle,
    IconComponent: DeliveryIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.POSITIVE,
  },
  [FEED_MESSAGE_TYPES.productWithdrawn]: {
    titleKey: messages.productWithdrawnTitle,
    IconComponent: AlertIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.NEGATIVE,
  },
  [FEED_MESSAGE_TYPES.productAddedToCatalog]: {
    titleKey: messages.productBackForStoreTitle,
    IconComponent: DeliveryIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.POSITIVE,
  },
  [FEED_MESSAGE_TYPES.alcoholLicenseFee]: {
    titleKey: messages.alcoholLicenseFee,
    IconComponent: AlcoholIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.NEGATIVE,
  },
  [FEED_MESSAGE_TYPES.alcoholLicenseRenewal]: {
    titleKey: messages.alcoholLicenseRenewal,
    IconComponent: AlcoholIcon,
    stylingType: FEED_MESSAGE_STYLING_TYPE.NEGATIVE,
  },
}
