import React from 'react'

import User from './User'
import CartButton from './CartButton'
import NotificationCenter from '../NotificationCenter'
import { UserControlsWrapper } from './styles'

const UserControls = ({ userBtnStyles, cartBtnStyles, paymentColorName }) => (
  <UserControlsWrapper>
    <NotificationCenter />
    <User userBtnStyles={userBtnStyles} paymentColorName={paymentColorName} />
    <CartButton cartBtnStyles={cartBtnStyles} />
  </UserControlsWrapper>
)

export default UserControls
