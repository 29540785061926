import Drinks from './Categories/U5-Drinks'
import Meat from './Categories/D5-Meat'
import Cheese from './Categories/H5-Cheese'
import Fish from './Categories/E5-Fish'
import Fruits from './Categories/F5-Fruits'
import Bread from './Categories/I5-Bread'
import Mushroom from './Categories/G5-Mushroom'
import Cupcake from './Categories/L5-Cupcake'
import Honey from './Categories/T5-Honey'
import Ketchup from './Categories/S5-Ketchup'
import Oil from './Categories/M5-Oil'
import Wheat from './Categories/O5-Wheat'
import Syrop from './Categories/K5-Syrop'
import Spices from './Categories/P5-Spices'
import Soup from './Categories/R5-Soup'
import Fries from './Categories/N5-Fries'
import Alkohol from './Categories/Y8-Alkohol'
import NonFood from './Categories/Z5-NonFood'
import Coffee from './Categories/W5-Coffee'
import Icecream from './Categories/J5-Icecream'
import Sushi from './Categories/B5-Sushi'
import Wege from './Categories/C5-Wege'

import Croissant from './Cuisines/Croissant'
import Breakfast from './Cuisines/Breakfast'
import Burger from './Cuisines/Burger'
import Kebab from './Cuisines/Kebab'
import Pierogi from './Cuisines/Pierogi'
import Pizza from './Cuisines/Pizza'
import CuisineSushi from './Cuisines/Sushi'
import Ramen from './Cuisines/Ramen'

export { default as DefaultCategoriesIcon } from './Categories/Default'
export { default as DefaultCuisinesIcon } from './Cuisines/Default'

export const CATEGORIES_MAP = {
  U5: Drinks,
  D5: Meat,
  H5: Cheese,
  E5: Fish,
  F5: Fruits,
  I5: Bread,
  G5: Mushroom,
  L5: Cupcake,
  T5: Honey,
  S5: Ketchup,
  M5: Oil,
  O5: Wheat,
  K5: Syrop,
  P5: Spices,
  R5: Soup,
  N5: Fries,
  Y8: Alkohol,
  Z5: NonFood,
  W5: Coffee,
  J5: Icecream,
  B5: Sushi,
  C5: Wege,
}

export const CUISINES_MAP = {
  ITALIAN: Pizza,
  ASIAN: Ramen,
  BREAKFAST: Breakfast,
  HOTEL: Croissant,
  KEBAB: Kebab,
  AMERICAN: Burger,
  POLISH: Pierogi,
  SUSHI: CuisineSushi,
}

export const ICONS_MAP = {
  ...CATEGORIES_MAP,
  ...CUISINES_MAP,
}
