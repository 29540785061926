import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Flex } from '@rebass/grid'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'

import { formatPrice } from 'utils'
import { useOfMaxWidth } from 'hooks'
import { EVENT_ORIGIN, trackPayInvoices } from 'services/analytics'
import { PAY_SCENARIO, PAYMENT_COLORS } from 'containers/Orders/consts'
import { orderActions } from 'containers/Orders/actions'
import { useGetTradeLimitQuery } from 'containers/Cart/rtkApi'
import {
  cartTotalSelector,
  inactiveCartProductsSelector,
  firstDeliverySelector,
  firstDeliveryLogisticLackAmountSelector,
  hasFloatingPriceSelector,
} from 'containers/Cart/selectors'
import { TinyText } from 'components/Text'

import { INVOICES_ROUTES } from 'views/Invoices/consts'
import messages from 'views/Cart/messages'
import { SCENARIO_CONFIG } from 'views/Cart/SummaryFooter/consts'
import { APP_BREAKPOINTS } from 'consts'
import { INVOICE_STATUS } from 'containers/Invoices/consts'
import { userDataSelector } from 'containers/UserInfo/selectors'

import { FloatingPriceWrapper } from 'components/FloatingPrice'
import theme from 'theme'
import { PayBtnWrapper, BtnTitle, PriceWrapper } from './styles'
import { getColors, getIcon, getTitle, getClickHandler } from './utils'
import TooltipComp from './TooltipComp'

const MainWrapper = ({
  children,
  useTooltip,
  firstDeliveryLogisticLackAmount,
  firstDelivery,
}) =>
  useTooltip ? (
    <TooltipComp
      {...{
        firstDeliveryLogisticLackAmount,
        firstDelivery,
      }}
    >
      {children}
    </TooltipComp>
  ) : (
    children
  )

const PayBtn = ({ isFixed }) => {
  const isMobile = useOfMaxWidth(APP_BREAKPOINTS.TMP_MAIN - 1)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const cartTotal = useSelector(cartTotalSelector)
  const hasFloatingPrice = useSelector(hasFloatingPriceSelector)
  const inactiveProducts = useSelector(inactiveCartProductsSelector)
  const hasInactiveProducts = !!inactiveProducts?.length
  const firstDelivery = useSelector(firstDeliverySelector)
  const firstDeliveryLogisticLackAmount = useSelector(
    firstDeliveryLogisticLackAmountSelector,
  )
  const { data: tradeLimit } = useGetTradeLimitQuery()
  const { hideDues, customerNo, storeId } = useSelector(userDataSelector)

  if (!tradeLimit) return null

  const disabled = firstDeliveryLogisticLackAmount > 0
  const onPayOrder = () =>
    dispatch(orderActions.createDelta(cartTotal, { hasInactiveProducts }))
  const onPayBills = () => {
    trackPayInvoices({ storeId, customerNo, origin: EVENT_ORIGIN.CART })
    navigate(
      `${INVOICES_ROUTES.ROOT}?${queryString.stringify({
        preselectStatus: INVOICE_STATUS.NOT_PAID_EXPIRED,
      })}`,
    )
  }

  const { scenario, paymentColorName, overdueAmount } = tradeLimit
  const { submitBg, submitBgHover, txtColor } = SCENARIO_CONFIG[scenario]
  const isGreenScenario = scenario === PAY_SCENARIO.GREEN
  const isYellowScenario = scenario === PAY_SCENARIO.YELLOW
  const isRedScenario = scenario === PAY_SCENARIO.RED
  const isRedClient = paymentColorName === PAYMENT_COLORS.RED

  const disabledBtn =
    (!isRedClient && (disabled || isRedScenario)) || (hideDues && isRedScenario)
  const { bg, bgHover, textColor } = getColors({
    isYellowScenario,
    isGreenScenario,
    disabled,
    submitBg,
    submitBgHover,
    txtColor,
  })

  const useTooltip =
    !isMobile &&
    !isRedClient &&
    !isRedScenario &&
    firstDeliveryLogisticLackAmount > 0

  return (
    <MainWrapper
      useTooltip={useTooltip}
      firstDeliveryLogisticLackAmount={firstDeliveryLogisticLackAmount}
      firstDelivery={firstDelivery}
    >
      <PayBtnWrapper
        data-test-id="cart_sticky_footer_btn"
        {...{ bg, bgHover, isFixed }}
        {...isRedClient && { className: 'pay-invoices' }}
        disableCursor={disabledBtn}
        onClick={getClickHandler({
          disabledBtn,
          isRedClient,
          onPayBills,
          onPayOrder,
          hideDues,
        })}
      >
        <BtnTitle bold withMargin color={textColor}>
          {getTitle({ isRedClient, isYellowScenario, isRedScenario, hideDues })}
        </BtnTitle>

        <Flex alignItems="center">
          {!(hideDues && isRedClient) && (
            <PriceWrapper>
              <TinyText color={textColor} style={{ whiteSpace: 'nowrap' }}>
                {formatMessage(messages.grossSum)}
              </TinyText>

              <BtnTitle bold color={textColor}>
                <FloatingPriceWrapper
                  containerStyle={{
                    justifyContent: 'flex-end',
                  }}
                  hasFloatingPrice={hasFloatingPrice}
                  iconBgColor={theme.colors.transparent}
                  iconColor={textColor}
                  iconBorderColor={textColor}
                >
                  {formatPrice(
                    isRedClient ? overdueAmount : cartTotal.totalGross,
                  )}
                </FloatingPriceWrapper>
              </BtnTitle>
            </PriceWrapper>
          )}

          {getIcon({
            textColor,
            disabled,
            isGreenScenario,
            isRedScenario,
            isRedClient,
            hideDues,
          })}
        </Flex>
      </PayBtnWrapper>
    </MainWrapper>
  )
}

export default PayBtn
