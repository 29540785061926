import { rtkApi } from 'services/api/rtkApi'
import { RTK_INVOICES_LIST_TAG } from 'containers/Invoices/List/rtkApi'

const paySelectionApi = rtkApi.injectEndpoints({
  endpoints: build => ({
    initPayment: build.mutation({
      query: invoices => ({
        url: 'me/payments',
        method: 'POST',
        data: {
          invoices: invoices.map(({ id, number, remainingAmount }) => ({
            id,
            documentNumber: number,
            amount: remainingAmount,
          })),
        },
      }),
      invalidatesTags: [RTK_INVOICES_LIST_TAG],
    }),
    settleInvoices: build.mutation({
      query: ({ overpaymentInvoices, invoicesToPay }) => ({
        url: 'me/invoices_settlements',
        method: 'POST',
        data: {
          overpayments: overpaymentInvoices.map(({ id, number }) => ({
            id,
            number,
          })),
          invoices_for_settlement: invoicesToPay.map(({ id, number }) => ({
            id,
            number,
          })),
        },
      }),
      invalidatesTags: [RTK_INVOICES_LIST_TAG],
    }),
  }),
})

export const {
  useInitPaymentMutation,
  useSettleInvoicesMutation,
} = paySelectionApi
