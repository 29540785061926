import { useEffect } from 'react'
import { useContentful } from 'utils/contentful'

const parseArticlesData = articles =>
  articles.map(({ fields, sys }) => ({
    title: fields.title,
    desc: fields.description,
    articleId: sys.id,
    body: fields.body,
  }))

const parseCategoriesData = data => {
  const { categories } = data.items[0].fields

  return categories.map(({ fields, sys }) => ({
    articles: parseArticlesData(fields.articles),
    title: fields.data.fields.title,
    icon: fields.data.fields.menuIcon.fields.file.url,
    categoryId: sys.id,
    img: fields.data.fields.backgroundImage.fields.file.url,
    theme: fields.data.fields.theme.fields,
    isPublic: fields.data.fields.public,
  }))
}

export const useKnowledgeBaseCategories = () => {
  const { fetchEntries, isLoading, items } = useContentful({
    clientQuery: {
      include: 3,
      content_type: 'knowledgeBase',
      'fields.platform': 'web',
    },
    responseParser: parseCategoriesData,
  })

  useEffect(() => {
    fetchEntries()
  }, [])

  return { isLoading, categories: items }
}
