export const PROFILE_ROUTES = {
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/profile/change-password',
  NEWSLETTER_FEEDBACK: '/profile/newsletter-feedback',
  NEWSLETTER_UNSUBSCRIBE_SUCCESS: '/profile/newsletter-unsubscribe-success',
  ACTIVATE_2FA: '/profile/activate-2fa',
  DEACTIVATE_2FA: '/profile/deactivate-2fa',
}

export const PROFILE_REDUCER_NAME = 'profile'
