import { createSelector } from 'reselect'
import { get, map, some, flatten, flow, filter } from 'lodash/fp'
import _getOr from 'lodash/fp/getOr'
import _map from 'lodash/fp/map'
import _flow from 'lodash/fp/flow'
import _find from 'lodash/fp/find'

import { makeDataSelector, makeErrorSelector } from 'utils/redux-utils'
import { calculateAmountByQuantity } from 'components/Product/utils'

import {
  ORDER_INFO_REDUCER_NAME,
  ORDER_ERRORS,
  ORDER_DETAILS_REDUCER_NAME,
} from './consts'

export const orderDetailsSelector = makeDataSelector([
  ORDER_INFO_REDUCER_NAME,
  ORDER_DETAILS_REDUCER_NAME,
])

export const orderDetailsErrorSelector = makeErrorSelector([
  ORDER_INFO_REDUCER_NAME,
  ORDER_DETAILS_REDUCER_NAME,
])

export const orderInfoSelector = createSelector(
  orderDetailsSelector,
  data => data || {},
)

export const orderErrorItemsSelector = createSelector(
  orderDetailsErrorSelector,
  flow(
    get('orders'),
    filter({ errorId: ORDER_ERRORS.POSITIONS_ERROR }),
    map('items'),
    flatten,
  ),
)

export const orderErrorCodeSelector = createSelector(
  orderDetailsErrorSelector,
  errorObj => errorObj?.code,
)

export const orderErrorSelector = createSelector(
  orderDetailsErrorSelector,
  errorObj => {
    const { orders, message } = errorObj || {}
    // identify ROUTES.CART_MISSING_ITEMS case or show generic error
    if (some({ errorId: ORDER_ERRORS.POSITIONS_ERROR })(orders)) {
      return ORDER_ERRORS.POSITIONS_ERROR
    }

    return message
  },
)

/* eslint-disable camelcase */
export const orderItemsErrorSelector = createSelector(
  orderDetailsErrorSelector,
  _flow(
    _getOr([], 'items'),
    _map(
      ({
        product_id,
        error_message,
        error_id,
        unit_of_measure,
        product: { units_of_measure },
      }) => {
        const unitData = _find({ unit_of_measure })(units_of_measure) || {}
        const stock = calculateAmountByQuantity(unitData, unitData.stock)

        return {
          productId: product_id,
          errorMessage: error_message,
          errorId: error_id,
          stock,
        }
      },
    ),
  ),
)
