import React from 'react'
import { useIntl } from 'react-intl'

import { INVOICE_TYPE_IDS } from 'containers/Invoices/List/consts'
import { NoInvoices as NoInvoicesIcon } from 'components/Icons'
import NoResults from 'components/NoResults'

import messages from '../../../messages'

const NoInvoices = ({ onGoToCatalog, onSelectAll, invoicesType }) => {
  const { formatMessage } = useIntl()
  const isAllCategory = invoicesType === INVOICE_TYPE_IDS.ALL

  const config = {
    containerTestId: isAllCategory ? 'all-category-no-invoices' : 'no-invoices',
    text: isAllCategory ? messages.emptyMsg : messages.categoryEmpty,
    btnTestId: 'no-invoices-btn',
    handleBtnClick: isAllCategory ? onGoToCatalog : onSelectAll,
    btnText: isAllCategory ? messages.goHomeBtn : messages.categoryEmptyBtn,
  }

  return (
    <NoResults
      icon={<NoInvoicesIcon />}
      text={formatMessage(config.text)}
      btnText={formatMessage(config.btnText)}
      handleBtnClick={config.handleBtnClick}
      containerTestId={config.containerTestId}
      btnTestId={config.btnTestId}
    />
  )
}

export default NoInvoices
