import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { values } from 'lodash/fp'
import { useMediaQuery } from 'react-responsive'

import DropdownBody from 'components/DropdownBody'
import PortalModal from 'components/modal/PortalModal'
import { BaseTextV2 } from 'components/Text'
import { ModalHeader } from 'components/modal/styles'
import { formatPrice } from 'utils'
import { userDataSelector } from 'containers/UserInfo/selectors'
import Loader from 'components/Loader'
import {
  trackOverpaymentsSettleInit,
  trackOverpaymentsDocSelectionChange,
  trackOverpaymentsSettlement,
} from 'services/analytics/finances'
import { notifyFailure, notifySuccess } from 'components/Product/utils'
import { useGetInvoicesQuery } from 'containers/Invoices/List/rtkApi'
import { INVOICE_TYPE_IDS } from 'containers/Invoices/List/consts'
import { useSettleInvoicesMutation } from 'containers/Invoices/PaySelection/rtkApi'
import LoaderFullHeight from 'components/LoaderFullHeight'

import { calculateInvoicesTotal } from './utils'
import InvoiceListItem from '../List/components/InvoiceListItem'
import Footer from './Footer'
import { PriceLabel } from './Footer/styles'
import messages from './messages'
import { SETTLE_MODAL_MOBILE_MAX_WIDTH } from './consts'
import {
  PortalModalStyle,
  TotalSubheader,
  Invoices,
  InvoiceContainer,
  ModalContentContainer,
  SettleModalScroll,
} from './styles'

const SettleModal = ({ onClose, invoicesToPayMap, invoicesToPayAmount }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [selectedInvoicesMap, setSelectedInvoicesMap] = useState(new Map())

  const {
    data: { invoices: overpaymentInvoices = [] } = {},
    isFetching: isOverpaymentInvoicesLoading,
  } = useGetInvoicesQuery({
    page: 1,
    perPage: 200, // overpayments modal doesn't support pagination, using some high page size
    type: INVOICE_TYPE_IDS.OVERPAID,
  })

  const [settleInvoices, { isLoading }] = useSettleInvoicesMutation()
  const userData = useSelector(userDataSelector)

  const overpaymentsTotal = useMemo(
    () => calculateInvoicesTotal(overpaymentInvoices),
    [overpaymentInvoices],
  )

  const selectedOverpaymentsTotal = useMemo(
    () => calculateInvoicesTotal([...selectedInvoicesMap.values()]),
    [selectedInvoicesMap],
  )

  const remainingAmountToFill = useMemo(
    () => invoicesToPayAmount - selectedOverpaymentsTotal,
    [invoicesToPayAmount, selectedOverpaymentsTotal],
  )

  const submitSettlement = useCallback(
    async () => {
      trackOverpaymentsSettlement({
        storeId: userData.storeId,
        amount: selectedOverpaymentsTotal,
        amountToPay: invoicesToPayAmount,
      })

      try {
        await settleInvoices({
          overpaymentInvoices: [...selectedInvoicesMap.values()],
          invoicesToPay: values(invoicesToPayMap),
        }).unwrap()
        dispatch(notifySuccess(formatMessage(messages.success)))
        onClose()
      } catch (e) {
        dispatch(notifyFailure(formatMessage(messages.tryAgain)))
      }
    },
    [
      invoicesToPayMap,
      selectedInvoicesMap,
      userData.storeId,
      selectedOverpaymentsTotal,
      invoicesToPayAmount,
    ],
  )

  useEffect(
    () => {
      trackOverpaymentsSettleInit({
        storeId: userData.storeId,
        amount: invoicesToPayAmount,
      })
    },
    [invoicesToPayAmount, userData.storeId],
  )

  return (
    <>
      {isLoading && <LoaderFullHeight />}
      <ModalHeader heading={formatMessage(messages.header)} onClick={onClose} />

      <TotalSubheader>
        <BaseTextV2 isSmall={{ lineHeight: true }}>
          {formatMessage(messages.allTotal)}
        </BaseTextV2>
        <PriceLabel data-test-id="all-invoices-total">
          {formatPrice(overpaymentsTotal)}
        </PriceLabel>
      </TotalSubheader>

      <SettleModalScroll>
        <Invoices>
          {isOverpaymentInvoicesLoading ? (
            <Loader />
          ) : (
            <>
              {overpaymentInvoices.map((invoice, index) => {
                const isInvoiceSelected = selectedInvoicesMap.has(invoice.id)
                return (
                  <InvoiceContainer key={invoice.id}>
                    <InvoiceListItem
                      isSelectable={
                        remainingAmountToFill > 0 || isInvoiceSelected
                      }
                      checkBoxSpacing
                      invoice={invoice}
                      isSelected={isInvoiceSelected}
                      onToggleSelect={() => {
                        const nextSelectedMap = new Map(selectedInvoicesMap)
                        if (nextSelectedMap.has(invoice.id)) {
                          nextSelectedMap.delete(invoice.id)
                        } else {
                          nextSelectedMap.set(invoice.id, invoice)
                        }

                        trackOverpaymentsDocSelectionChange({
                          storeId: userData.storeId,
                          amount: calculateInvoicesTotal([
                            ...nextSelectedMap.values(),
                          ]),
                          index,
                          selected: nextSelectedMap.has(invoice.id),
                        })
                        setSelectedInvoicesMap(nextSelectedMap)
                      }}
                    />
                  </InvoiceContainer>
                )
              })}
            </>
          )}
        </Invoices>
      </SettleModalScroll>
      <Footer
        selectedOverpaymentsTotal={selectedOverpaymentsTotal}
        toPayAmount={formatPrice(invoicesToPayAmount)}
        remainingAmountToFill={remainingAmountToFill}
        settleBtnDisabled={!selectedInvoicesMap.size}
        onSubmitSettlement={submitSettlement}
      />
    </>
  )
}

const SettleModalResponsive = props => {
  const isMobile = !useMediaQuery({
    query: `(min-width: ${SETTLE_MODAL_MOBILE_MAX_WIDTH})`,
  })

  const content = <SettleModal {...props} />

  if (isMobile) {
    return (
      <>
        <PortalModalStyle />
        <DropdownBody
          isMobile
          onOverlayClick={props.onClose}
          testId="invoices-settlement-modal"
          className="InvoicesSettleModal"
        >
          <ModalContentContainer>{content}</ModalContentContainer>
        </DropdownBody>
      </>
    )
  }

  return (
    <PortalModal
      onClose={props.onClose}
      isOpen
      testId="invoices-settlement-modal"
    >
      <PortalModalStyle />
      {content}
    </PortalModal>
  )
}

export default SettleModalResponsive
