import React, { useCallback, useMemo, useEffect, useState } from 'react'
import usePrevious from 'use-previous'
import useEmblaCarousel from 'embla-carousel-react'

import { APP_BREAKPOINTS } from 'consts'

import {
  CarouselOverflowContainer,
  CarouselScrollContainer,
  DefaultCarouselWrapper,
} from './styles'
import DefaultCarouselControls from './DefaultCarouselControls'

const DESKTOP_BREAKPOINT = `(min-width: ${APP_BREAKPOINTS.DESKTOP_START}px)`

const Carousel = ({
  children,
  hideControls = false,
  CarouselWrapper = DefaultCarouselWrapper,
  ScrollContainer = CarouselScrollContainer,
  OverflowContainer = CarouselOverflowContainer,
  customControls,
  carouselConfig,
  carouselPlugins = [],
  onSlideChange,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedPageIndex, setSelectedPageIndex] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [slidesPerPage, setSlidesPerPage] = useState(0)

  const CarouselControls = customControls?.component || DefaultCarouselControls

  const prevIndex = usePrevious(selectedIndex)
  const prevPage = usePrevious(selectedPageIndex)

  const childrenLength = useMemo(
    () =>
      React.Children.toArray(children).filter(child =>
        React.isValidElement(child),
      ).length,
    [children],
  )

  const setSelectedPage = index => {
    if (prevPage !== index && prevPage !== undefined) {
      onSlideChange?.(index)
    }

    setSelectedPageIndex(index)
  }

  useEffect(
    () => {
      if (selectedIndex <= 0) {
        setSelectedPage(0)
        return
      }

      if (childrenLength - 1 - selectedIndex < slidesPerPage) {
        setSelectedPage(numberOfPages - 1)
        return
      }

      if (selectedIndex > prevIndex) {
        setSelectedPage(selectedPageIndex + 1)
      } else {
        setSelectedPage(selectedPageIndex - 1)
      }
    },
    [selectedIndex],
  )

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      breakpoints: {
        [DESKTOP_BREAKPOINT]: {
          watchDrag: false,
        },
      },
      align: 'start',
      containScroll: 'trimSnaps',
      inViewThreshold: 1,
      skipSnaps: true,
      ...carouselConfig,
    },
    carouselPlugins,
  )

  const handlePrev = useCallback(
    () => emblaApi?.scrollTo(emblaApi.selectedScrollSnap() - slidesPerPage),
    [emblaApi, slidesPerPage],
  )

  const handleNext = useCallback(
    () => emblaApi?.scrollTo(emblaApi.selectedScrollSnap() + slidesPerPage),
    [emblaApi, slidesPerPage],
  )

  const handleSelectIndex = useCallback(
    index => {
      emblaApi?.scrollTo(index)
    },
    [emblaApi],
  )

  const onInit = useCallback(embla => {
    embla.scrollTo(0, true)
    setSlidesPerPage(embla.slidesInView(true).length)
  }, [])

  useEffect(
    () => {
      if (!slidesPerPage) {
        setNumberOfPages(0)
      } else {
        setNumberOfPages(Math.ceil(childrenLength / slidesPerPage))
      }
    },
    [slidesPerPage, childrenLength],
  )

  const onSelect = useCallback(embla => {
    setSelectedIndex(embla.selectedScrollSnap())
  }, [])

  useEffect(
    () => {
      if (!emblaApi) {
        return
      }
      onInit(emblaApi)
      onSelect(emblaApi)
      emblaApi.on('init', onInit)
      emblaApi.on('reInit', onInit)
      emblaApi.on('resize', onInit)
      emblaApi.on('select', onSelect)
      // eslint-disable-next-line consistent-return
      return () => {
        emblaApi.off('init', onInit)
        emblaApi.off('reInit', onInit)
        emblaApi.off('resize', onInit)
        emblaApi.off('select', onSelect)
      }
    },
    [emblaApi, onSelect, onInit],
  )

  const areControlsVisible = numberOfPages > 1 && !hideControls

  return (
    <CarouselWrapper>
      <OverflowContainer ref={emblaRef}>
        <ScrollContainer>{children}</ScrollContainer>
      </OverflowContainer>

      {(areControlsVisible || customControls) && (
        <CarouselControls
          emblaApi={emblaApi}
          handlePrev={handlePrev}
          handleNext={handleNext}
          numberOfPages={numberOfPages}
          selectedPageIndex={selectedPageIndex}
          handleSelectIndex={handleSelectIndex}
          {...customControls?.props}
        />
      )}
    </CarouselWrapper>
  )
}

export default Carousel
