import { createClient } from 'contentful'
import { useCallback, useState } from 'react'

import {
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_HOST,
} from 'consts'

export const client = createClient({
  space: CONTENTFUL_SPACE_ID,
  accessToken: CONTENTFUL_ACCESS_TOKEN,
  environment: CONTENTFUL_ENVIRONMENT,
  host: CONTENTFUL_HOST,
})

export const useContentful = ({ clientQuery, responseParser }) => {
  const [items, setItems] = useState([])
  const [isLoading, setLoading] = useState(false)

  const fetchEntries = useCallback(async () => {
    setLoading(true)
    try {
      const response = await client.getEntries(clientQuery)

      setItems(() => responseParser(response))
    } catch (e) {
      console.log('Error accessing contentful entries:', e)
    } finally {
      setLoading(false)
    }
  }, [])

  return { items, fetchEntries, isLoading }
}
