import { makeMessages, makeTranslation } from 'utils'

const messages = {
  newPasswordLengthError: {
    id: 'validators.new_password_length_error',
  },
  required: {
    id: 'validators.blank',
  },
  settings: { id: 'navbar.menu.settings' },
  changePassword: 'other.changePassword',
  tryAgain: {
    id: 'error.tryAgain',
  },
  error: {
    id: 'error.generic',
  },
  activate2FASuccess: 'activate2FA.successToast',
  deactivate2FASuccess: 'deactivate2FA.successToast',
}

export default makeMessages(messages, makeTranslation('profile'))
