import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { generatePath, useLocation } from 'react-router-dom'
import usePrevious from 'use-previous'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { Flex } from '@rebass/grid'
import 'react-circular-progressbar/dist/styles.css'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { getPromoProduct } from 'containers/Promotions/Products/actions'
import { makePromotionProductSelectors } from 'containers/Promotions/Products/selectors'

import getCart from 'containers/Cart/actions'
import { cartProductUnitsSelector } from 'containers/Cart/selectors'
import { isFetchingSelector } from 'hocs/selectors'
import {
  getPrizeUnit,
  getRegularUnit,
  isValueBasedPromoType,
} from 'containers/Promotions/utils'
import { formatPrice } from 'utils'
import { ROUTES } from 'consts'
import { CART_INFO_REDUCER_NAME } from 'containers/Cart/consts'
import { isCartRoute } from 'views/Cart/utils'

import PrizeProgress from 'components/Product/PromoGroup/PrizeRow/PrizeProgress'
import {
  PrizeRowContainer,
  PrizeRowDescriptionContainer,
  PrizeRowImg,
  PrizeRowTextContainer,
  PrizeRowTextSpan,
  PrizeRowTextTitle,
  PrizeRowDetails,
  PrizeRowPrice,
  PrizeRowCount,
  PrizeRowCountQuantity,
  CircularProgressbarContainer,
  PrizeRowButtons,
  UnitCounterContainer,
  UnitCounter,
  UnitPriceContainer,
  UnitPrice,
  PrizeUnitPrice,
  RewardProgressTextContainer,
  UnitSelectionContainer,
} from './styles'
import messages from './messages'

const PrizeRow = ({ promo, closeModal }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { formatMessage } = useIntl()
  const { reward } = promo
  const productId = reward?.productId
  const isValueBasedPromo = isValueBasedPromoType(promo)
  const units = useSelector(cartProductUnitsSelector(productId))
  const prizeUnit = getPrizeUnit(units)
  const regularUnit = getRegularUnit(units)
  const prizeProductSelectors = useMemo(
    () => makePromotionProductSelectors(productId),
    [productId],
  )
  const prize = useSelector(prizeProductSelectors.data)
  const isCartInfoFetching = useSelector(
    isFetchingSelector(CART_INFO_REDUCER_NAME),
  )
  const pointsThreshold = reward?.threshold
  const inCartQuantity = prize?.unitsOfMeasure[0].inCartQuantity
  const prizeQtyInCart = prize?.inUserCatalog
    ? prizeUnit?.quantity || 0
    : inCartQuantity
  const { maxAmountReached, circleProgress } = useMemo(
    () => ({
      maxAmountReached: reward.maxApplicationsReached,
      circleProgress: (reward.thresholdProgression / pointsThreshold) * 100,
    }),
    [pointsThreshold, reward],
  )

  const totalThresholdsMet = reward.availableNo
  const prevTotalThresholdsMet = usePrevious(totalThresholdsMet)

  const prevInCartQty = usePrevious(inCartQuantity)

  useEffect(
    () => {
      if (
        prize &&
        prevTotalThresholdsMet !== totalThresholdsMet &&
        prevTotalThresholdsMet !== undefined
      ) {
        prize.inUserCatalog
          ? dispatch(getCart.delta())
          : dispatch(getPromoProduct.delta({ productId }))
      }
    },
    [prize, prevTotalThresholdsMet, totalThresholdsMet],
  )

  useEffect(
    () => {
      if (
        prize?.inUserCatalog &&
        prevInCartQty !== inCartQuantity &&
        prevInCartQty !== undefined
      ) {
        dispatch(getCart.delta())
      }

      if (prevInCartQty === undefined && inCartQuantity && !units) {
        dispatch(getCart.delta({}, { suppressGetDeliveryDates: true }))
      }
    },
    [prevInCartQty, inCartQuantity],
  )

  if (prize) {
    const { unitsOfMeasure, inUserCatalog } = prize
    const unit = unitsOfMeasure[0]
    const { priceNet, stock, multiplier } = unit
    const price = formatPrice(
      isValueBasedPromo && !inUserCatalog ? reward.priceNet : priceNet,
    )
    const rewardPrice = formatPrice(reward.priceNet)
    const maxValue = (inUserCatalog ? stock : reward.availableNo) * multiplier
    const cartBtnDisabled = !inUserCatalog && !reward.toCollect
    const prizeQtyToClaim = reward.toCollect

    const renderCatalogPrizeMsg = () => {
      if (maxAmountReached) {
        return formatMessage(messages.maxClaimed)
      }

      if (prizeQtyToClaim && !isCartInfoFetching?.fetching) {
        return formatMessage(
          messages[prizeUnit?.quantity ? 'canClaimAnother' : 'canClaim'],
        )
      }

      if (prizeUnit?.quantity) {
        return formatMessage(messages.productClaimed)
      }

      return formatMessage(messages.keepAdding)
    }

    const renderOutOfCatalogPrizeMsg = () => {
      if (maxAmountReached) {
        return formatMessage(messages.maxClaimed)
      }

      return formatMessage(messages.keepAdding)
    }

    const isRegularInCart =
      inUserCatalog &&
      !prizeUnit?.quantity &&
      regularUnit?.quantity &&
      !prizeQtyToClaim

    return (
      <PrizeRowContainer data-test-id="reward-row">
        <PrizeRowDescriptionContainer>
          <PrizeRowImg
            isTransparent
            onSelect={inUserCatalog ? closeModal : undefined}
            productPath={
              inUserCatalog &&
              generatePath(ROUTES.PRODUCT_DETAILS, {
                id: productId,
              })
            }
            outOfStock={false}
            product={prize}
          />
          <PrizeRowTextContainer>
            <PrizeRowTextTitle>
              {inUserCatalog
                ? renderCatalogPrizeMsg()
                : renderOutOfCatalogPrizeMsg()}
            </PrizeRowTextTitle>
            <PrizeRowTextSpan>
              {prize.name}
              <RewardProgressTextContainer>
                {isRegularInCart && !isValueBasedPromo ? (
                  formatMessage(messages.regularInCart)
                ) : (
                  <>
                    {isValueBasedPromo && (
                      <>
                        <span>
                          {formatPrice(reward.thresholdDelta, {
                            withoutCurrency: true,
                          })}
                          {' / '}
                          {formatPrice(pointsThreshold)}
                        </span>
                        {reward.uniqueProductsThreshold > 1 &&
                          formatMessage(messages.uniqueProducts, {
                            count: reward.uniqueProductsCount,
                            threshold: reward.uniqueProductsThreshold,
                          })}
                      </>
                    )}
                    <div className="flex gap-1">
                      <span>
                        {formatMessage(messages.toClaim, {
                          amount: prizeQtyToClaim,
                        })}
                      </span>
                      <span>
                        {!!prizeQtyInCart &&
                          !isValueBasedPromo &&
                          formatMessage(messages.claimed, {
                            amount: prizeQtyInCart,
                          })}
                      </span>
                    </div>
                  </>
                )}
              </RewardProgressTextContainer>
            </PrizeRowTextSpan>
          </PrizeRowTextContainer>
        </PrizeRowDescriptionContainer>
        <PrizeRowDetails>
          {!isValueBasedPromo ? (
            <PrizeRowCount {...{ inUserCatalog, prizeQtyToClaim }}>
              {inUserCatalog ? (
                <Flex>
                  <UnitCounterContainer>
                    <UnitCounter>{regularUnit?.quantity || 0}</UnitCounter>
                    <UnitCounter>{prizeUnit?.quantity || 0}</UnitCounter>
                  </UnitCounterContainer>
                  <UnitPriceContainer>
                    <UnitPrice>{price}</UnitPrice>
                    <PrizeUnitPrice
                      isHighlighted={prizeQtyToClaim || prizeUnit?.quantity}
                    >
                      {rewardPrice}
                    </PrizeUnitPrice>
                  </UnitPriceContainer>
                </Flex>
              ) : (
                <>
                  <PrizeRowCountQuantity {...{ prizeQtyToClaim }}>
                    {maxAmountReached ? 'Max' : prizeQtyToClaim}
                  </PrizeRowCountQuantity>
                  <CircularProgressbarContainer>
                    <CircularProgressbar
                      value={maxAmountReached ? 100 : circleProgress}
                      strokeWidth={50}
                      styles={buildStyles({
                        pathColor:
                          theme.colors[prizeQtyToClaim ? 'white' : 'secondary'],
                        trailColor: prizeQtyToClaim ? '#64b77c' : '#adddb1',
                        strokeLinecap: 'butt',
                      })}
                    />
                  </CircularProgressbarContainer>
                </>
              )}
            </PrizeRowCount>
          ) : (
            <>
              {!regularUnit?.quantity ? (
                <PrizeRowPrice>
                  {inUserCatalog ? rewardPrice : price}
                </PrizeRowPrice>
              ) : (
                <UnitSelectionContainer>
                  <UnitCounterContainer>
                    <UnitCounter data-test-id="regular-price-qty">
                      {regularUnit?.quantity || 0}
                    </UnitCounter>
                    <UnitCounter data-test-id="reward-price-qty">
                      {prizeUnit?.quantity || 0}
                    </UnitCounter>
                  </UnitCounterContainer>
                  <UnitPriceContainer>
                    <UnitPrice>{price}</UnitPrice>
                    <PrizeUnitPrice
                      isHighlighted={prizeQtyToClaim || prizeUnit?.quantity}
                    >
                      {rewardPrice}
                    </PrizeUnitPrice>
                  </UnitPriceContainer>
                </UnitSelectionContainer>
              )}
              <PrizeProgress reward={reward} />
            </>
          )}
          <PrizeRowButtons
            {...{ productId, prizeQtyToClaim, cartBtnDisabled, maxValue }}
            product={prize}
            unitData={unit}
            justifyContent={['center', 'space-between']}
            fontSize={13}
            neverDisableMinus
            disableTrash
            refetchOnCartClick={isCartRoute(location)}
            addRemoveCallback={({ removedFromCart }) => {
              if (removedFromCart) {
                dispatch(getPromoProduct.delta({ productId: reward.productId }))
              }
            }}
            disableInput
            noSpaceBetween
            hideStar
          />
        </PrizeRowDetails>
      </PrizeRowContainer>
    )
  }

  return null
}

export default PrizeRow
