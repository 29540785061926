import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { USER_INFO_REDUCER_NAME } from 'containers/UserInfo/consts'
import { isFetchingSelector } from 'hocs/selectors'
import LoaderFullHeight from 'components/LoaderFullHeight'
import Breadcrumbs from 'components/Layout/Breadcrumbs/MainPage'
import { PageTitleV2 } from 'components/Text'
import useChangedLocationModal from 'hooks/useChangedLocationModal'

import Data from './Data'
import BottomSection from './BottomSection'
import messages from './messages'
import { ProfileWrapper, ProfileTitleDivider } from './styles'

const Profile = () => {
  const { formatMessage } = useIntl()
  const userData = useSelector(userDataSelector)

  const { fetching } = useSelector(isFetchingSelector([USER_INFO_REDUCER_NAME]))
  useChangedLocationModal({ isReady: !fetching && userData })

  return (
    <>
      {fetching && <LoaderFullHeight />}
      <ProfileWrapper col="12">
        <Breadcrumbs />

        <PageTitleV2>{formatMessage(messages.settings)}</PageTitleV2>
        <ProfileTitleDivider />
        <Data userData={userData} formatMessage={formatMessage} />
        <BottomSection
          contacts={userData.contacts}
          formatMessage={formatMessage}
        />
      </ProfileWrapper>
    </>
  )
}

export default Profile
