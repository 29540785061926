import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  label: 'label',
  placeholder: 'placeholder',
  sendAgain: 'sendAgain',
  help: 'help',
  sendAgainTimer: 'sendAgainTimer',
  invalid: 'invalid',
  emptyField: { id: 'login.emptyField' },
  needHelp: { id: 'login.needHelp' },
  contactsMail: { id: 'support.mail' },
  contactsPhone: { id: 'support.phone' },
}

export default makeMessages(messages, makeTranslation('otp'))
