import React from 'react'

import theme from 'theme'

const Logistic = ({ color = theme.colors.infoBlue2 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_37387_80961)">
      <path
        d="M18 15V4H6V15H18Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 15V4L1.59806 3.11961C1.28866 3.05773 1 3.29438 1 3.6099V15.3901C1 15.7056 1.28866 15.9423 1.59806 15.8804L6 15Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M18 15V4L22.4019 3.11961C22.7113 3.05773 23 3.29438 23 3.6099V15.3901C23 15.7056 22.7113 15.9423 22.4019 15.8804L18 15Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M16.5857 21H17.7C17.8657 21 18 20.8657 18 20.7V18.3C18 18.1343 17.8657 18 17.7 18H6.3C6.13431 18 6 18.1343 6 18.3V20.7C6 20.8657 6.13431 21 6.3 21H7.41429C7.57997 21 7.71429 20.8657 7.71429 20.7V18.3C7.71429 18.1343 7.8486 18 8.01429 18H15.9857C16.1514 18 16.2857 18.1343 16.2857 18.3V20.7C16.2857 20.8657 16.42 21 16.5857 21Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect x="8" y="10" width="4" height="3" rx="1" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_37387_80961">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Logistic
