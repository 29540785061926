import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { useQuery } from 'hooks'
import { downloadBase64Pdf } from 'utils'
import { DEFAULT_INVOICE_TYPE } from 'views/Invoices/consts'
import { INVOICE_TYPE } from 'containers/Invoices/List/consts'
import {
  DotsMenuLarge as MenuIcon,
  Download as DownloadIcon,
} from 'components/Icons'
import ModalBackgroundOverlay from 'components/ModalBackgroundOverlay'
import { notifyFailure } from 'components/Product/utils'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { trackInvoiceDownload } from 'services/analytics'
import { useLazyGetInvoiceFileQuery } from 'containers/Invoices/List/rtkApi'
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from 'components/Popup'

import messages from 'views/Invoices/messages'
import {
  InvoiceMenuWrapper,
  InvoiceMenuContainer,
  InvoiceMenuMobileContainer,
  InvoiceMenuItemContainer,
  InvoiceMenuItemText,
  InvoiceMenuBtn,
} from './styles'

const InvoiceMenu = ({
  isFileAvailable,
  invoiceId,
  isDesktop,
  isAlignedTop,
}) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [fetchInvoiceFile] = useLazyGetInvoiceFileQuery()

  const { type } = useQuery()
  const { customerNo } = useSelector(userDataSelector)
  const subsectionMsgKey = INVOICE_TYPE.get(type || DEFAULT_INVOICE_TYPE).title

  const handleTargetClick = () => {
    if (!isFileAvailable) return

    if (!isDesktop) {
      setMobileMenuOpen(true)
    }
  }

  const handleInvoiceDownload = async () => {
    trackInvoiceDownload({
      customerNo,
      subsection: formatMessage(messages[subsectionMsgKey]),
    })

    try {
      const { data, name } = await fetchInvoiceFile({ invoiceId }).unwrap()
      downloadBase64Pdf({
        href: `data:application/pdf;base64,${data}`,
        fileName: name,
      })
    } catch (e) {
      dispatch(notifyFailure(formatMessage(messages.downloadInvoiceError)))
    } finally {
      setMobileMenuOpen(false)
    }
  }

  const invoiceMenuContent = (
    <PopoverClose>
      <InvoiceMenuItemContainer onClick={handleInvoiceDownload}>
        <DownloadIcon />
        <InvoiceMenuItemText>
          {formatMessage(messages.downloadInvoice)}
        </InvoiceMenuItemText>
      </InvoiceMenuItemContainer>
    </PopoverClose>
  )

  return (
    <InvoiceMenuWrapper
      onClick={ev => ev.stopPropagation()}
      $isAlignedTop={isAlignedTop}
      $isActive={isFileAvailable}
    >
      <Popover
        disableArrow
        disabled={!isFileAvailable || !isDesktop}
        placement="bottom-end"
      >
        <PopoverContent>
          <InvoiceMenuContainer>{invoiceMenuContent}</InvoiceMenuContainer>
        </PopoverContent>
        <PopoverTrigger>
          <InvoiceMenuBtn
            data-test-id="invoice-menu-btn"
            onClick={handleTargetClick}
          >
            <MenuIcon />
          </InvoiceMenuBtn>
        </PopoverTrigger>
      </Popover>

      {/* handling invoice menu on mobile views */}
      {isMobileMenuOpen && (
        <InvoiceMenuMobileContainer>
          {invoiceMenuContent}
        </InvoiceMenuMobileContainer>
      )}
      <ModalBackgroundOverlay
        style={{ zIndex: theme.zIndex.modalOverlay }}
        enabled={isMobileMenuOpen}
        onTouchStart={() => setMobileMenuOpen(false)}
      />
    </InvoiceMenuWrapper>
  )
}

export default InvoiceMenu
