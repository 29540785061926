import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  usernameLabel: 'usernameLabel',
  passwordLabel: 'passwordLabel',
  forgotPassword: { id: 'fields.forgot_password' },
  submit: 'submit',
  needHelp: 'needHelp',
  contactUs: 'contactUs',
  invalidCreds: 'invalidCreds',
  emptyField: 'emptyField',
  dontHaveAccount: 'dontHaveAccount',
  register: 'register',
  companyName: { id: 'footer.companyName' },
  contactsMail: { id: 'support.mail' },
  contactsPhone: { id: 'support.phone' },
  autoLogoutMessage: 'autoLogoutMessage',
  otpDescription: 'otpDescription',
  tryAgain: { id: 'error.tryAgain' },
}

export default makeMessages(messages, makeTranslation('login'))
