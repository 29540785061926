import { useSelector, useDispatch } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { noop } from 'lodash'

import { ROUTES } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'
import cartActionsGroup from 'containers/Cart/actions'
import { getInvoicesSummaryActions } from 'containers/Invoices/actions'
import { useChangeLocationMutation } from 'containers/Locations/rtkApi'
import {
  EVENT_ORIGIN,
  NAV_BAR_EVENTS,
  NOTIFICATION_EVENTS,
  trackSimpleEvent,
} from 'services/analytics'
import { FEED_MESSAGE_TYPES } from '../../consts'

// needed since some notifications have multiple customerNos in array
const getNotifCustomerNo = (data, customerNo) => {
  if (data.customer_nos) {
    return (
      data.customer_nos.find(el => el === customerNo) || data.customer_nos[0]
    )
  }

  return data.customer_no
}

const useFeedItemNavigation = () => {
  const navigate = useNavigate()
  const { customerNo } = useSelector(userDataSelector)

  const navigateFromFeedItem = (
    path,
    { notifCustomerNo, hasLocationChanged },
  ) => {
    navigate(path, {
      state: {
        notifProps: {
          customerNo: notifCustomerNo,
          fromCustomerNo: customerNo,
          hasLocationChanged,
        },
      },
    })
  }

  return [navigateFromFeedItem]
}

const useFeedItemHandler = () => {
  const [navigateFromFeedItem] = useFeedItemNavigation()

  const handlers = {
    [FEED_MESSAGE_TYPES.logisticLimitNotSet]: props => {
      navigateFromFeedItem(
        generatePath(ROUTES.ORDERS.DETAILS, {
          orderId: props.item.data.order_id,
        }),
        props,
      )
    },
    [FEED_MESSAGE_TYPES.productBackForStore]: props => {
      navigateFromFeedItem(
        generatePath(ROUTES.PRODUCT_DETAILS, {
          id: props.item.data.product.id,
        }),
        props,
      )
    },
    [FEED_MESSAGE_TYPES.productWithdrawn]: props => {
      navigateFromFeedItem(
        generatePath(ROUTES.PRODUCT_DETAILS, {
          id: props.item.data.product.id,
        }),
        props,
      )
    },
  }

  const getFeedItemHandler = feedMsgType => handlers[feedMsgType] || noop
  return [getFeedItemHandler]
}

const useFeedItemClickHandler = markAsRead => {
  const dispatch = useDispatch()

  const { customerNo } = useSelector(userDataSelector)

  const [changeLocation] = useChangeLocationMutation()
  const [getFeedItemHandler] = useFeedItemHandler()

  const handleFeedItemClick = async item => {
    markAsRead(item)

    trackSimpleEvent(NOTIFICATION_EVENTS.NOTIFICATION_CLICK, {
      notif_type: item.source.key,
      created_at: item.inserted_at,
    })

    const feedItemHandler = getFeedItemHandler(item.source.key)
    const notifCustomerNo = getNotifCustomerNo(item.data, customerNo)

    if (customerNo !== notifCustomerNo) {
      await changeLocation({ customerNo: notifCustomerNo })
      trackSimpleEvent(NAV_BAR_EVENTS.SWITCH_ACCOUNT_CLICKED, {
        notif_type: item.source.key,
        origin: EVENT_ORIGIN.NOTIFICATION_REDIRECT,
      })

      // refetch top nav data after changed location
      dispatch(cartActionsGroup.delta())
      dispatch(getInvoicesSummaryActions.delta())

      return feedItemHandler({
        item,
        notifCustomerNo,
        hasLocationChanged: true,
      })
    }

    return feedItemHandler({ item, notifCustomerNo })
  }

  return [handleFeedItemClick]
}

export default useFeedItemClickHandler
