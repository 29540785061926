import { combineReducers } from 'redux-immutable'
import { fromJS } from 'immutable'
import { get, findIndex } from 'lodash'
import { getOr, filter, compose } from 'lodash/fp'

import { reducer, initialState } from 'utils/simpleDataReducer'

import { deleteCartItem } from '../Cart/actions'
import { templateStatusChange } from '../Templates/actions'

import { ORDER_DETAILS_REDUCER_NAME } from './consts'
import { orderActions } from './actions'

const makeProductReplacer = itemsKey => (state, product) => {
  const items = state.getIn(['data', itemsKey])
  if (items) {
    return state.setIn(
      ['data', itemsKey],
      items.map(item => {
        if (item.getIn(['product', 'id']) === product.id) {
          return item.set('product', fromJS(product))
        }

        return item
      }),
    )
  }

  return state
}

// eslint-disable-next-line default-param-last
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case orderActions.UPDATE: {
      const updatePath = ['data', 'orderItems']
      const collection = state.getIn(updatePath)
      const productId = get(action, 'additionalData.productId')
      const unitOfMeasure = get(action, 'additionalData.unitOfMeasure')
      const findByObj = { productId, unitOfMeasure }

      const itemIndex = productId
        ? findIndex(collection ? collection.toJS() : {}, findByObj)
        : -1

      return state
        .set('isFetching', false)
        .set('finished', true)
        .setIn([...updatePath, itemIndex], fromJS(action.data))
    }
    case deleteCartItem.SUCCESS: {
      const {
        additionalData: { productId },
      } = action

      const error = state.get('error')
      if (error) {
        const errorItems = compose(
          filter(product => product.productId !== productId),
          getOr([], 'items'),
        )(error)

        return state.set('error', { ...error, items: errorItems })
      }

      return state
    }
    case templateStatusChange.toString(): {
      return makeProductReplacer('orderItems')(state, action.payload)
    }
    default: {
      return reducer(state, action, orderActions)
    }
  }
}

export default combineReducers({
  [ORDER_DETAILS_REDUCER_NAME]: orderReducer,
})
