import * as yup from 'yup'

import messages from './messages'

export default yup.object().shape({
  otp: yup
    .string()
    .required(messages.emptyField)
    .min(6, messages.invalid),
})
