import { makeMessages, makeTranslation } from 'utils'

const messages = {
  button: 'button',
  disableToast: 'disableToast',
  disableText: 'disableText',
  disableHeader: 'disableHeader',
  disableAccept: 'disableAccept',
  cancel: {
    id: 'common.cancel',
  },
  tryAgain: {
    id: 'error.tryAgain',
  },
}

export default makeMessages(messages, makeTranslation('profile.other.2FA'))
