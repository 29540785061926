import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  description: 'description',
  emailOption: 'emailOption',
  emailLabel: 'emailLabel',
  emailHint: 'emailHint',
  begin: 'begin',
  otpDescription: 'otpDescription',
  otpSubmit: 'otpSubmit',
  tryAgain: { id: 'error.tryAgain' },
}

export default makeMessages(messages, makeTranslation('profile.activate2FA'))
