import React from 'react'

import theme from 'theme'

const Loader = ({
  width = 24,
  height = 24,
  color = theme.colors.secondary,
  ...rest
}) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...rest}
  >
    <path
      d="M12 2C11.4477 2 11 2.44772 11 3C11 3.55228 11.4477 4 12 4V2ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4Z"
      fill="url(#paint0_linear_5293_122772)"
    />
    <path
      d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3"
      stroke="url(#paint1_linear_5293_122772)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5293_122772"
        x1="16.5"
        y1="3"
        x2="16.5"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color} />
        <stop offset="1" stopColor={color} stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5293_122772"
        x1="7.5"
        y1="4.5"
        x2="7.5"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0" />
        <stop offset="1" stopColor={color} stopOpacity="0.4" />
      </linearGradient>
    </defs>
  </svg>
)

export default Loader
