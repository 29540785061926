/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'

import MainPageBreadcrumbs from 'components/Layout/Breadcrumbs/MainPage'
import { PageTitleV2 } from 'components/Text'
import DocumentTile from 'components/Documents/DocumentTile'
import SkeletonBlock from 'components/Skeletons/SkeletonBlock'
import { useContentful } from 'utils/contentful'
import { parseDocumentEntries } from './utils'

import { ItemsGrid, Divider, Container } from './styles'

const Documents = ({ title, contentfulSlug }) => {
  const { fetchEntries, isLoading, items } = useContentful({
    clientQuery: {
      'fields.slug': contentfulSlug,
      content_type: 'documentsPage',
    },
    responseParser: parseDocumentEntries,
  })

  useEffect(
    () => {
      fetchEntries()
    },
    [fetchEntries],
  )

  return (
    <Container>
      <MainPageBreadcrumbs />

      <PageTitleV2>{title}</PageTitleV2>
      <Divider />
      <ItemsGrid>
        {isLoading
          ? new Array(3)
              .fill(undefined)
              .map((_, index) => (
                <SkeletonBlock
                  key={index}
                  data-test-id="document-skeleton"
                  $width="100%"
                  $height="160px"
                  $borderRadius="16px"
                />
              ))
          : items.map(item => (
              <DocumentTile
                key={item.id}
                isLoading={isLoading}
                title={item.title}
                currentDocumentUrl={item.currentFileUrl}
                previousVersionFileUrl={item.previousVersionFileUrl}
              />
            ))}
      </ItemsGrid>
    </Container>
  )
}

export default Documents
