import React, { useEffect, useMemo } from 'react'
import { generatePath, useParams, useNavigate } from 'react-router-dom'

import { ROUTES } from 'consts'
import Breadcrumbs from 'components/Layout/Breadcrumbs/MainPage'
import LoaderFullHeight from 'components/LoaderFullHeight'
import CategoriesList from 'components/KnowledgeBase/CategoriesList'
import { useKnowledgeBaseCategories } from 'components/KnowledgeBase/utils'
import EntryContent from './EntryContent'

const KnowledgeBase = () => {
  const navigate = useNavigate()
  const params = useParams()

  const { categories } = useKnowledgeBaseCategories()

  // temporary until FAQ main page not implemented
  useEffect(
    () => {
      if (categories.length && !params.category) {
        navigate(
          generatePath(ROUTES.KNOWLEDGE_BASE, {
            category: categories[0].categoryId,
          }),
          { replace: true },
        )
      }
    },
    [categories, params.category],
  )

  const { selectedCategory, selectedArticle } = useMemo(
    () => {
      if (!categories.length) return {}

      const category = categories.find(
        ({ categoryId }) => categoryId === params.category,
      )

      return {
        selectedCategory: category,
        selectedArticle: category?.articles.find(
          ({ articleId }) => articleId === params.article,
        ),
      }
    },
    [params, categories],
  )

  const breadcrumbs = useMemo(
    () => {
      if (selectedArticle) {
        return [
          {
            title: selectedCategory.title,
            path: generatePath(ROUTES.KNOWLEDGE_BASE, {
              category: selectedCategory.categoryId,
            }),
          },
        ]
      }

      return []
    },
    [selectedCategory, selectedArticle],
  )

  if (!(selectedArticle || selectedCategory)) return <LoaderFullHeight />

  return (
    <div className="flex flex-col w-full gap-6 pb-14 lg:gap-8">
      <div className="flex flex-col pt-4 pb-4 px-6 border-b border-solid border-grey-300 lg:px-8">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <h1
          data-test-id="kb-title"
          className="text-blue-900 text-[22px] leading-[30px] font-semibold pt-8 lg:text-[28px] lg:leading-10 lg:pt-14"
        >
          {selectedArticle?.title || selectedCategory.title}
        </h1>
      </div>
      <div className="flex gap-12 px-6 lg:px-8">
        <div className="w-[284px] flex-shrink-0 hidden lg:block">
          <div className="sticky top-[calc(theme(inset.nav-height)+32px)] lg:top-[calc(theme(inset.lg-nav-height)+32px)]">
            <CategoriesList categories={categories} />
          </div>
        </div>
        <EntryContent
          selectedCategory={selectedCategory}
          selectedArticle={selectedArticle}
        />
      </div>
    </div>
  )
}

export default KnowledgeBase
