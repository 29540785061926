import { connect } from 'react-redux'

import { bindActionCreators } from 'utils/redux-utils'

import { passwordActions } from './actions'

export * from './consts'
export * from './selectors'
export * from './actions'

const mapDispatchToProps = bindActionCreators({
  setPassword: passwordActions.delta,
})

export default connect(
  null,
  mapDispatchToProps,
)
