import { makeMessages, makeTranslation } from 'utils'

const messages = {
  otpDescription: 'otpDescription',
  otpSubmit: 'otpSubmit',
  tryAgain: {
    id: 'error.tryAgain',
  },
}

export default makeMessages(messages, makeTranslation('profile.deactivate2FA'))
