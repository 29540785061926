import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import AnimateHeight from 'react-animate-height'
import { useLocation } from 'react-router-dom'

import { X, UserMenu as UserMenuIcon } from 'components/Icons'
import { BadgeAlert as AlertIcon } from 'components/Icons/NavMenu'

import {
  trackSimpleEvent,
  NAV_BAR_EVENTS,
  PROFILE_EVENTS,
  EVENT_ORIGIN,
} from 'services/analytics'
import ContactFormModalWrapper from 'components/ContactForm/ContactFormModal/Wrapper'
import { useGetTradeLimitQuery } from 'containers/Cart/rtkApi'

import UserMenu from '../../UserMenu'
import messages from '../../UserMenu/messages'
import CategoriesList from './CategoriesList'

import NavBar from '../NavBar'
import { Separator } from '../../styles'
import {
  MobileNavWrapper,
  MobileBurgerWrapper,
  DropdownWrapper,
  Section,
  SectionsWrapper,
  UserMenuContainer,
} from '../styles'
import { BadgeContainer } from './styles'

const MobileBurger = ({
  userBtnStyles: { isAlert, bgColor, contentColor } = {},
  mobileMenuOffset = 56,
}) => {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const [isNavOpen, setNavOpen] = useState(false)
  const [isCategoriesOpen, setCategoriesOpen] = useState(false)
  const [isContactFormOpen, setContactFormOpen] = useState(false)
  const closeCategoriesList = () => setCategoriesOpen(false)

  const { data: tradeLimit } = useGetTradeLimitQuery()

  useEffect(
    () => {
      // when main nav is closing also close categories list
      if (!isNavOpen) {
        closeCategoriesList()
      }
    },
    [isNavOpen],
  )

  useEffect(
    () => {
      setNavOpen(false)
    },
    [location],
  )

  const toggleMobileNav = () => {
    setNavOpen(prevState => !prevState)
    trackSimpleEvent(NAV_BAR_EVENTS.USER_MENU_CLICKED)
  }

  const handleOpenContactForm = () => {
    setContactFormOpen(true)
    trackSimpleEvent(PROFILE_EVENTS.CONTACT_FORM_CLICKED, {
      origin: EVENT_ORIGIN.USER_MENU,
      button: formatMessage(messages.contactForm),
    })
  }

  return (
    <>
      <ContactFormModalWrapper
        isContactFormOpen={isContactFormOpen}
        onContactFormClose={() => setContactFormOpen(false)}
      />
      <MobileBurgerWrapper $isNavOpen={isNavOpen}>
        <MobileNavWrapper
          onClick={toggleMobileNav}
          data-test-id="mobile-burger-icon"
        >
          {isNavOpen ? (
            <X data-test-id="mobile-burger-close-icon" />
          ) : (
            <>
              {isAlert && (
                <BadgeContainer isAlert data-test-id="mobile-burger-alert-icon">
                  <AlertIcon {...{ bgColor, contentColor }} />
                </BadgeContainer>
              )}
              <UserMenuIcon />
            </>
          )}
        </MobileNavWrapper>

        <DropdownWrapper mobileMenuOffset={mobileMenuOffset}>
          <AnimateHeight
            duration={300}
            height={isNavOpen ? 'auto' : 0}
            data-test-id="mobile-burger-wrapper"
          >
            <TransitionGroup
              childFactory={child =>
                React.cloneElement(child, {
                  classNames: isCategoriesOpen
                    ? 'right-to-left'
                    : 'left-to-right',
                  timeout: 300,
                })
              }
            >
              <CSSTransition
                key={isCategoriesOpen}
                classNames="right-to-left"
                timeout={300}
              >
                {isCategoriesOpen ? (
                  <CategoriesList
                    closeCategoriesList={closeCategoriesList}
                    mobileMenuOffset={mobileMenuOffset}
                  />
                ) : (
                  <SectionsWrapper mobileMenuOffset={mobileMenuOffset}>
                    <Section>
                      <NavBar
                        toggleMobileCategories={state =>
                          setCategoriesOpen(state)
                        }
                        isMobile
                      />
                    </Section>

                    <UserMenuContainer>
                      <Separator />
                      <UserMenu
                        tradeLimit={tradeLimit}
                        openContactForm={handleOpenContactForm}
                      />
                    </UserMenuContainer>
                  </SectionsWrapper>
                )}
              </CSSTransition>
            </TransitionGroup>
          </AnimateHeight>
        </DropdownWrapper>
      </MobileBurgerWrapper>
    </>
  )
}

export default MobileBurger
