import { INVOICE_TYPE_EVENTS } from './consts'

export const FINANCES = {
  PAYMENT_INIT: 'Initiated P24 payment',

  INVOICE_SELECT: 'Selected invoice',

  // OR stands for overpayments reckoning
  OR_INIT: 'Initiated payment reckoning',
  OR_SELECT_DOCUMENT: 'Selected document in the OR process',
  OR_DESELECT_DOCUMENT: 'Deselected document in the OR process',
  OR_SETTLE: 'Triggered reckoning process',
  OR_CLOSE: 'Closed modal',
  FROM_DATE_SELECTED: 'Selected from date for invoices',
  TO_DATE_SELECTED: 'Selected to date for invoices',
  CLICKED_PAY_INVOICES: 'clicked_pay_invoices',
  CLICKED_PAYMENT_HISTORY: 'clicked_payments_history',
  PAYMENTS_TILE_EXPANDED: 'payments_tile_expanded',
  PAYMENTS_TILE_COLLAPSED: 'payments_tile_collapsed',
  CLICKED_CART: 'clicked_cart',
  INVOICE_DOWNLOAD: 'invoice_download',
}

export const trackClickPaymentsHistory = ({ customerNo, origin, storeId }) => {
  window.dataLayer.push({
    event: FINANCES.CLICKED_PAYMENT_HISTORY,
    customer_no: customerNo,
    store_id: String(storeId),
    origin,
  })
}

export const trackSelectedInvoice = ({ storeId, invoiceId }) => {
  window.dataLayer.push({
    event: FINANCES.INVOICE_SELECT,
    store_id: String(storeId),
    invoice_id: invoiceId,
  })
}

export const trackOverpaymentsSettleInit = ({ storeId, amount }) => {
  window.dataLayer.push({
    event: FINANCES.OR_INIT,
    store_id: String(storeId),
    amount_to_pay: amount,
  })
}

export const trackOverpaymentsDocSelectionChange = ({
  storeId,
  amount,
  index,
  selected,
}) => {
  window.dataLayer.push({
    event: selected
      ? FINANCES.OR_SELECT_DOCUMENT
      : FINANCES.OR_DESELECT_DOCUMENT,
    store_id: String(storeId),
    amount_selected: amount,
    doc_pos: index,
  })
}

export const trackOverpaymentsSettlement = ({
  storeId,
  amount,
  amountToPay,
}) => {
  window.dataLayer.push({
    event: FINANCES.OR_SETTLE,
    store_id: String(storeId),
    amount_selected: amount,
    amount_to_pay: amountToPay,
  })
}

export const trackOverpaymentsClose = ({ storeId }) => {
  window.dataLayer.push({
    event: FINANCES.OR_CLOSE,
    store_id: String(storeId),
  })
}

export const trackInvoiceTypeChange = ({ storeId, type }) => {
  window.dataLayer.push({
    event: INVOICE_TYPE_EVENTS[type],
    store_id: String(storeId),
  })
}

export const trackFromDateSelected = (storeId, date) => {
  window.dataLayer.push({
    event: FINANCES.FROM_DATE_SELECTED,
    store_id: String(storeId),
    from_date: date,
  })
}

export const trackToDateSelected = (storeId, date) => {
  window.dataLayer.push({
    event: FINANCES.TO_DATE_SELECTED,
    store_id: String(storeId),
    to_date: date,
  })
}

export const trackPayInvoices = ({ storeId, customerNo, origin }) => {
  window.dataLayer.push({
    event: FINANCES.CLICKED_PAY_INVOICES,
    store_id: String(storeId),
    customer_no: customerNo,
    origin,
  })
}

export const trackGoToCartClick = ({ origin }) => {
  window.dataLayer.push({
    event: FINANCES.CLICKED_CART,
    origin,
  })
}

export const trackFinanceTileExpanded = ({ storeId, customerNo, origin }) => {
  window.dataLayer.push({
    event: FINANCES.PAYMENTS_TILE_EXPANDED,
    store_id: String(storeId),
    customer_no: customerNo,
    origin,
  })
}

export const trackFinanceTileCollapsed = ({ storeId, customerNo, origin }) => {
  window.dataLayer.push({
    event: FINANCES.PAYMENTS_TILE_COLLAPSED,
    store_id: String(storeId),
    customer_no: customerNo,
    origin,
  })
}

export const trackInvoiceDownload = ({ subsection, customerNo }) => {
  window.dataLayer.push({
    event: FINANCES.INVOICE_DOWNLOAD,
    subsection,
    customer_no: customerNo,
  })
}
