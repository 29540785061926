import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

const renderOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => (
      <div className="mt-6">
        <h3 className="text-blue-900 text-[20px] leading-8 font-semibold">
          {children}
        </h3>
      </div>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <div className="my-2">
        <p className="text-blue-900 text-[16px] leading-6">{children}</p>
      </div>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc list-inside pl-2 ">{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal list-inside marker:text-blue-900 marker:text-[16px] marker:leading-6">
        {children}
      </ol>
    ),
    [BLOCKS.LIST_ITEM]: node => (
      <li>
        <span className="text-blue-900 text-[16px] leading-6">
          {node.content[0].content[0].value}
        </span>
      </li>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <div className="my-4 self-center">
        <img
          alt={node.data.target.fields.title}
          src={node.data.target.fields.file.url}
        />
      </div>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        target="_blank"
        href={node.data.uri}
        className="text-green-600 underline underline-offset-2"
      >
        {children}
      </a>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: children => (
      <strong className="font-semibold">{children}</strong>
    ),
  },
}

const Article = ({ selectedArticle }) => (
  <div
    data-test-id="kb-art-content"
    className="flex flex-col gap-4 [&>*:first-child]:mt-[6px] lg:max-w-[50%]"
  >
    {documentToReactComponents(selectedArticle.body, renderOptions)}
  </div>
)

export default Article
