import React from 'react'
import { prop, propOr } from 'lodash/fp'

import pdpImg from './assets/pdp.png'
import listingImg from './assets/listing.png'
import cartTemplatesImg from './assets/cartTemplates.png'

import { ProductImg } from './styledComponents'

export const PRODUCT_IMAGE_SIZES = {
  S: 's',
  M: 'm',
  L: 'l',
}

export const ALT_IMAGES = {
  [PRODUCT_IMAGE_SIZES.S]: cartTemplatesImg,
  [PRODUCT_IMAGE_SIZES.M]: listingImg,
  [PRODUCT_IMAGE_SIZES.L]: pdpImg,
}

// TODO: refactor usages to get rid of product prop

const ProductImage = ({
  size = PRODUCT_IMAGE_SIZES.S,
  product,
  imageSrc,
  outOfStock,
  suppressImgGraying,
  ...rest
}) => {
  const imgArr = prop('images', product)
  const src = prop('imageUrl', product)
  const active = propOr(true, 'active')(product)
  const isImgGrayed = outOfStock || !active

  const handleImgLoadError = ev => {
    ev.target.onerror = null // eslint-disable-line no-param-reassign
    ev.target.src = ALT_IMAGES[size] // eslint-disable-line no-param-reassign
  }

  let image = src
  if (imgArr && imgArr[0]) {
    switch (size) {
      case 's':
        image = imgArr[0].urlSmall
        break
      case 'm':
        image = imgArr[0].urlMedium
        break
      case 'l':
        image = imgArr[0].urlBig
        break
      default:
        image = src
    }
  }

  const hasImgSrc = !!(imageSrc || image)
  const dataTestId = hasImgSrc
    ? `product-img#${product?.id}`
    : 'placeholder-product-img'

  return (
    <ProductImg
      data-test-id={dataTestId}
      {...{ active }}
      isImgGrayed={suppressImgGraying ? false : isImgGrayed}
      onError={handleImgLoadError}
      src={imageSrc || image || ALT_IMAGES[size]}
      {...rest}
    />
  )
}

export default ProductImage
