import React, { useState } from 'react'
import { useNavigate, generatePath, useMatch } from 'react-router-dom'

import theme from 'theme'

import { ROUTES } from 'consts'
import { cn } from 'utils'
import { ArrowDownV2 as ArrowIcon } from 'components/Icons'

const ListItem = ({ category }) => {
  const { articles, title, icon, categoryId } = category
  const navigate = useNavigate()
  const { params = {} } = useMatch(ROUTES.KNOWLEDGE_BASE) || {}

  // set expanded on initial render if landed on article inside current category
  const [isExpanded, setIsExpanded] = useState(
    () => params.article && params.category === categoryId,
  )

  const navigateToEntry = article =>
    navigate(
      generatePath(ROUTES.KNOWLEDGE_BASE, {
        category: categoryId,
        article,
      }),
    )

  const isCategorySelected = !params.article && params.category === categoryId

  return (
    <div
      data-test-id={`kb-list-cat#${title}`}
      className="flex flex-col gap-[2px]"
    >
      <div
        data-test-id={`kb-list-item#${title}`}
        className={cn(
          'flex items-center rounded-lg',
          isCategorySelected && 'bg-green-600',
        )}
      >
        <button
          type="button"
          disabled={isCategorySelected}
          className="flex w-full px-4 py-2 gap-4 rounded-s-lg hover:bg-grey-200"
          onClick={() => navigateToEntry()}
        >
          <img
            alt=""
            src={icon}
            style={
              isCategorySelected
                ? {
                    filter:
                      'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(339deg) brightness(106%) contrast(105%)',
                  }
                : {}
            }
          />
          <span
            className={cn(
              'text-blue-900 text-[12px] leading-6 font-medium text-left',
              isCategorySelected && 'text-white',
            )}
          >
            {title}
          </span>
        </button>
        <div
          className={cn(
            'h-6 w-[1px] mx-[1px] bg-grey-300',
            isCategorySelected && 'bg-white opacity-20',
          )}
        />
        <button
          data-test-id={`kb-cat-expand-btn#${title}`}
          type="button"
          className={cn(
            'flex px-5 py-3 rounded-e-lg hover:bg-grey-200',
            isCategorySelected && 'hover:bg-green-500',
          )}
          onClick={() => setIsExpanded(state => !state)}
        >
          <div
            className={cn(
              'transition duration-300 ease-out',
              isExpanded && 'rotate-180',
            )}
          >
            <ArrowIcon
              color={
                isCategorySelected ? theme.colors.white : theme.colors.primary
              }
            />
          </div>
        </button>
      </div>
      {isExpanded &&
        articles.map(({ title: artTitle, articleId }) => {
          const isArticleSelected = params.article === articleId

          return (
            <button
              key={artTitle}
              data-test-id={`kb-list-item#${artTitle}`}
              type="button"
              disabled={isArticleSelected}
              className={cn(
                'flex pl-14 pr-4 py-2 rounded-lg hover:bg-grey-200',
                isArticleSelected && 'bg-green-600 hover:bg-green-600',
              )}
              onClick={() => navigateToEntry(articleId)}
            >
              <span
                className={cn(
                  'text-blue-900 text-[12px] leading-5 text-left',
                  isArticleSelected && 'text-white',
                )}
              >
                {artTitle}
              </span>
            </button>
          )
        })}
    </div>
  )
}

export default ListItem
