import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { NotificationBell } from 'components/Icons'
import { APP_BREAKPOINTS } from 'consts'
import { useOfMinWidth } from 'hooks'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'
import {
  EVENT_ORIGIN,
  NOTIFICATION_EVENTS,
  trackSimpleEvent,
} from 'services/analytics'
import {
  HEADER_DROPDOWN_MOBILE_TRANSITIONS,
  HEADER_DROPDOWN_TRANSITIONS,
} from 'components/Popup/transitions'
import { useRequestNotifAuthTokenMutation } from 'services/api/rtkApi'

import { useNotificationFeed } from './utils'
import NotificationCenterModal from './NotificationCenterModal'
import { NotificationCounter, NotificationsBtn } from './styles'

const NotificationCenter = ({ authToken }) => {
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  const { markAsRead, markAllAsRead, items, metadata } = useNotificationFeed(
    authToken,
  )

  // need to align header close button with trigger button
  const offsetValues = useMemo(
    () => {
      if (isDesktop) {
        return { main: 20, cross: 16 }
      }

      if (isTablet) {
        return { main: 12, cross: 8 }
      }

      return { main: 8 }
    },
    [isDesktop, isTablet],
  )

  const unreadCount = metadata.unread_count > 99 ? 99 : metadata.unread_count

  return (
    <Popover
      onOpen={() =>
        trackSimpleEvent(NOTIFICATION_EVENTS.NOTIFICATIONS_CLICKED, {
          origin: EVENT_ORIGIN.NAVIGATION,
        })
      }
      disableArrow
      placement="bottom-end"
      offsetValues={offsetValues}
    >
      <PopoverTrigger asChild>
        <NotificationsBtn data-test-id="notification-center-btn">
          {!!unreadCount && (
            <NotificationCounter data-test-id="unread-counter">
              {unreadCount}
            </NotificationCounter>
          )}
          <NotificationBell />
        </NotificationsBtn>
      </PopoverTrigger>

      <PopoverContent
        style={{
          width: !isTablet && '100%',
        }}
        withOverlay={isTablet}
        transitions={
          isTablet
            ? HEADER_DROPDOWN_TRANSITIONS
            : HEADER_DROPDOWN_MOBILE_TRANSITIONS
        }
      >
        <NotificationCenterModal
          items={items}
          markAsRead={markAsRead}
          markAllAsRead={markAllAsRead}
        />
      </PopoverContent>
    </Popover>
  )
}

export default () => {
  const [authToken, setAuthToken] = useState(null)
  const userData = useSelector(userDataSelector)

  const [requestNotifAuthToken] = useRequestNotifAuthTokenMutation()
  const handleAuthTokenRequest = useCallback(
    async () => {
      const result = await requestNotifAuthToken()

      if (result?.error) {
        console.log('Error getting Knock auth token', result.error)
        return setAuthToken(null)
      }

      return setAuthToken(result?.data?.authToken)
    },
    [requestNotifAuthToken],
  )

  useEffect(() => {
    handleAuthTokenRequest()
  }, [])

  // render NotificationCenter only if accountId and authToken are available to fetch feed
  if (userData.accountId && authToken) {
    return <NotificationCenter authToken={authToken} />
  }

  return null
}
