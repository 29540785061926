import React from 'react'
import { Box, Flex } from '@rebass/grid'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { prop } from 'lodash/fp'

import { formatPrice } from 'utils'
import theme from 'theme'

import { Row } from 'components/Layout'
import { TinyText } from 'components/Text'
import {
  invoicesPaginationSelector,
  totalInvoicesToPaySelecor,
} from 'containers/Invoices/List/selectors'
import { cartTotalSelector } from 'containers/Cart/selectors'
import { useGetTradeLimitQuery } from 'containers/Cart/rtkApi'
import TextTooltip from 'components/Tooltip/TextTooltip'

import messages from 'views/Cart/messages'
import ProgressIndicator from './ProgressIndicator'
import { ProgressWrapper, TooltipContent, Circle } from './styles'

const LimitProgress = () => {
  const { formatMessage } = useIntl()

  const invoicesPagination = useSelector(invoicesPaginationSelector)
  const unpaidInvoicesCount = prop('totalCount', invoicesPagination)
  const { data: tradeLimitData = {} } = useGetTradeLimitQuery()
  const invoicesTotal = useSelector(totalInvoicesToPaySelecor)
  const cartTotal = useSelector(cartTotalSelector)

  const tooltipContentRendered = (
    <TooltipContent>
      <Flex alignItems="center">
        <Circle bg={theme.colors.gray2} />
        <FormattedMessage {...messages.invoicesToPay} />
      </Flex>
      <Flex alignItems="center">
        <Circle bg={theme.colors.primary} />
        <FormattedMessage {...messages.availableLimit} />
      </Flex>
      <Flex alignItems="center">
        <Circle bg={theme.colors.secondary} />
        <FormattedMessage {...messages.cartValue} />
      </Flex>
    </TooltipContent>
  )

  return (
    <Flex alignItems="center">
      <ProgressWrapper>
        <TextTooltip
          textContainerStyle={{ padding: 0 }}
          content={tooltipContentRendered}
        >
          <ProgressIndicator
            tradeLimit={tradeLimitData.tradeLimit}
            cartTotal={cartTotal.totalGross}
            maxValue={tradeLimitData.tradeLimit + invoicesTotal}
            {...{ invoicesTotal }}
          />
        </TextTooltip>
      </ProgressWrapper>

      <Box ml={theme.spacing.sm} width="100%">
        <Row>
          <TinyText>
            <FormattedMessage {...messages.availableLimit} />:
          </TinyText>

          <TinyText bold>{formatPrice(tradeLimitData.tradeLimit)}</TinyText>
        </Row>

        {unpaidInvoicesCount > 0 && (
          <Row>
            <TinyText>
              <FormattedMessage
                {...messages.invoicesAmount}
                values={{
                  invoicesCount: unpaidInvoicesCount,
                  invoices: formatMessage(messages.invoices, {
                    number: unpaidInvoicesCount,
                  }),
                }}
              />
            </TinyText>
            <TinyText bold>{formatPrice(invoicesTotal)}</TinyText>
          </Row>
        )}
      </Box>
    </Flex>
  )
}

export default LimitProgress
