import { rtkApi } from 'services/api/rtkApi'
import { INVOICES_PER_PAGE_SIZE } from 'containers/Invoices/consts'

export const RTK_INVOICES_LIST_TAG = 'InvoicesList'

const invoicesListApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: [RTK_INVOICES_LIST_TAG],
  })
  .injectEndpoints({
    endpoints: build => ({
      getInvoices: build.query({
        query: ({ perPage = INVOICES_PER_PAGE_SIZE, page = 1, type }) => ({
          url: 'me/invoices',
          params: { perPage, page, type },
        }),
        transformResponse(res) {
          const { counters } = res.meta
          const types = counters.reduce(
            (acc, counter) => ({
              ...acc,
              [counter.name]: {
                ...counter,
              },
            }),
            {},
          )

          return { ...res, meta: { ...res.meta, types } }
        },
        providesTags: [RTK_INVOICES_LIST_TAG],
      }),

      getInvoiceFile: build.query({
        query: ({ invoiceId }) => ({
          url: `me/invoices/${invoiceId}/file`,
          meta: {
            suppress404Redirect: true,
          },
        }),
      }),
    }),
  })

export const {
  useGetInvoicesQuery,
  useLazyGetInvoiceFileQuery,
} = invoicesListApi
