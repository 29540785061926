import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { APP_BREAKPOINTS, ROUTES } from 'consts'
import { useRemoteConfig } from 'containers/RemoteConfigProvider'
import { useOfMinWidth } from 'hooks'
import { PROFILE_ROUTES } from 'containers/Profile/consts'

import HeaderWithNavigation from './HeaderWithNavigation'
import SimpleHeader from './SimpleHeader'
import MaintenanceToast from './MaintenanceToast'
import { HeaderContainer } from './styles'

// TODO refactor components structure to follow relations
const Header = ({ isLoggedIn, pathname }) => {
  const { config } = useRemoteConfig()
  const { maintenanceToast } = config

  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  const [mobileMenuOffset, setMobileMenuOffset] = useState(0)
  const displaySimpleHeader = [
    ROUTES.SET_LOCATION,
    ROUTES.CHANGE_LOCATION,
    PROFILE_ROUTES.ACTIVATE_2FA,
    PROFILE_ROUTES.DEACTIVATE_2FA,
  ].includes(pathname)

  const headerRef = useRef(null)
  useEffect(() => setMobileMenuOffset(headerRef?.current?.offsetHeight), [
    isDesktop,
  ])

  return (
    <HeaderContainer
      isMobile={!isDesktop}
      className="mainHeader"
      ref={headerRef}
    >
      <MaintenanceToast config={maintenanceToast} />
      {isLoggedIn && !displaySimpleHeader ? (
        <HeaderWithNavigation
          isMobile={!isDesktop}
          pathname={pathname}
          mobileMenuOffset={mobileMenuOffset}
        />
      ) : (
        <SimpleHeader isMobile={!isDesktop} isLoggedIn={isLoggedIn} />
      )}
    </HeaderContainer>
  )
}

Header.propTypes = {
  pathname: PropTypes.string,
}

export default Header
