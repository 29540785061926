import React, { useMemo, useState, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { compose } from 'lodash/fp'

import { withSpinner } from 'hocs'
import { getBrowserDashboardLayout } from 'views/Dashboard/utils'

import { PageTitle } from 'components/Text'
import categoriesContainer, {
  CATEGORY_REDUCER_NAME,
} from 'containers/Categories'
import campaignsContainer from 'containers/Campaigns'
import { ContentWrapper } from 'components/Layout'
import { withPageView, SCREENS } from 'services/analytics'
import { retailTrackHomeView } from 'services/analytics/retail'
import InvoicesTile from 'views/Dashboard/Invoices'
import DeliveriesTile from 'views/Dashboard/Deliveries'
import CampaignTile from 'views/Dashboard/Campaigns'
import OrdersTile from 'views/Dashboard/OrdersTile'
import AccountDetails from 'views/Dashboard/AccountDetails'
import TileControls from 'views/Dashboard/TileControls'

import Recommendations from 'views/Dashboard/Recommendations'
import FilteredPagesTile from 'views/Dashboard/FilteredPagesTile'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { useGetTradeLimitQuery } from 'containers/Cart/rtkApi'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import messages from './messages'
import PanelBox from './PanelBox'
import {
  CategoriesSection,
  DashboardTilesGrid,
  DashboardHeaderContainer,
  DashboardHeader,
  HeaderActionsContainer,
  HeaderActionsSeparator,
} from './styles'

const Categories = ({ allCategoriesList }) => {
  const { formatMessage } = useIntl()
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)
  const [isTopCampaignsLayout, setTopCampaignsLayout] = useState(
    getBrowserDashboardLayout,
  )

  const userData = useSelector(userDataSelector)
  const { data: tradeLimit } = useGetTradeLimitQuery()
  const { offerDetails } = userData
  const filteredPageTilesCount = useMemo(
    () => {
      if (offerDetails) {
        return (
          offerDetails.hasNovelties +
          offerDetails.hasActiveClearances +
          offerDetails.hasBestsellers +
          offerDetails.hasActivePromotions
        )
      }
      return 0
    },
    [offerDetails],
  )

  useEffect(() => {
    retailTrackHomeView()
  }, [])

  return (
    <ContentWrapper col="12">
      <DashboardHeaderContainer>
        <DashboardHeader data-test-id="dashboard-header">
          {formatMessage(messages.dashboardHeader)}
        </DashboardHeader>
        <HeaderActionsContainer>
          <AccountDetails userData={userData} />
          {isDesktop && (
            <>
              <HeaderActionsSeparator />
              <TileControls
                isTopCampaignsLayout={isTopCampaignsLayout}
                setTopCampaignsLayout={setTopCampaignsLayout}
              />
            </>
          )}
        </HeaderActionsContainer>
      </DashboardHeaderContainer>

      <DashboardTilesGrid
        className={isTopCampaignsLayout && 'group top-campaigns'}
        $isTopCampaignsLayout={isTopCampaignsLayout}
        $fullCampaigns={!filteredPageTilesCount}
      >
        <DeliveriesTile />
        <CampaignTile />
        <InvoicesTile tradeLimit={tradeLimit} />
        <OrdersTile />
        <FilteredPagesTile
          tileCount={filteredPageTilesCount}
          offerDetails={offerDetails}
        />
      </DashboardTilesGrid>

      <Recommendations />

      <PageTitle>{formatMessage(messages.header)}</PageTitle>

      <CategoriesSection col="12" data-hj-ignore-attributes>
        {Object.values(allCategoriesList).map(cat => (
          <PanelBox key={cat.id} {...cat} />
        ))}
      </CategoriesSection>
    </ContentWrapper>
  )
}

export default compose(
  withPageView(SCREENS.PRODUCTS),
  categoriesContainer,
  campaignsContainer,
  withSpinner(CATEGORY_REDUCER_NAME),
)(Categories)
