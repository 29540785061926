import { INVOICE_TYPE_IDS } from 'containers/Invoices/List/consts'

export const INVOICES_DESKTOP_START = '640px'

export const INVOICES_ROUTES = {
  ROOT: '/invoices',
  PAY_PROCESSING: '/invoices/pay/:paymentId',
}

export const DEFAULT_INVOICE_TYPE = INVOICE_TYPE_IDS.TO_PAY

export const INVOICE_FILE_STATUS = {
  PROCESSING: 'processing',
  AVAILABLE: 'available',
}
