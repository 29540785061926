import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  useAuthenticatedKnockClient,
  useNotifications,
  useNotificationStore,
} from '@knocklabs/react-core'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { KNOCK_API_KEY, KNOCK_IN_APP_CHANNEL } from 'consts'
import { useRequestNotifAuthTokenMutation } from 'services/api/rtkApi'

import { FEED_MESSAGE_MAP } from './consts'

export const useNotificationFeed = authToken => {
  const userData = useSelector(userDataSelector)

  const [requestNotifAuthToken] = useRequestNotifAuthTokenMutation()
  const onUserTokenExpiring = useCallback(
    async () => {
      const { data, error } = await requestNotifAuthToken()

      if (error) {
        console.log('Error getting Knock auth token', error)
        return ''
      }

      return data?.authToken
    },
    [requestNotifAuthToken],
  )

  // some methods/requests on feedClient don't work with numbers
  const accountId = userData.accountId?.toString()
  const knockClient = useAuthenticatedKnockClient(
    KNOCK_API_KEY,
    accountId,
    authToken,
    {
      onUserTokenExpiring,
    },
  )
  const feedClient = useNotifications(knockClient, KNOCK_IN_APP_CHANNEL)
  const { items, metadata } = useNotificationStore(feedClient)

  const filteredItems = useMemo(
    () => items.filter(item => FEED_MESSAGE_MAP[item.source.key]),
    [items],
  )

  const knockHandlers = useMemo(
    () => ({
      markAsRead: value => feedClient.markAsRead(value),
      markAllAsRead: () => feedClient.markAllAsRead(),
    }),
    [feedClient],
  )

  useEffect(
    () => {
      feedClient.fetch()
    },
    [feedClient],
  )

  return {
    items: filteredItems,
    metadata,
    ...knockHandlers,
  }
}
