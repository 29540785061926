import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'lodash/fp'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import theme from 'theme'
import { ROUTES } from 'consts'
import { hideInvoicesSelector } from 'containers/UserInfo/selectors'
import { withPageView, SCREENS } from 'services/analytics'
import { H4 } from 'components/Text'
import InfoPanel from 'components/InfoPanel'
import { INVOICE_TYPE_IDS } from 'containers/Invoices/List/consts'

import { INVOICES_ROUTES } from '../consts'
import messages from '../messages'
import { useGetInvoicesDataByQuery, useSelectedInvoicesToPay } from '../hooks'

import LayoutWrapper from './components/LayoutWrapper'
import List from './components/InvoicesList'
import NoInvoices from './components/NoInvoices'
import PaySummary from './components/PaySummary'
import { ListWrapper } from './styles'

const InvoicesList = props => {
  const hideInvoices = useSelector(hideInvoicesSelector)
  const { formatMessage } = useIntl()
  if (hideInvoices) {
    return (
      <LayoutWrapper>
        <InfoPanel mt={theme.spacing.sm}>
          <H4 breakLines>{formatMessage(messages.hideInvoicesInfo)}</H4>
        </InfoPanel>
      </LayoutWrapper>
    )
  }
  return <InvoicesListAfterGuard {...props} />
}

const InvoicesListAfterGuard = () => {
  const navigate = useNavigate()
  const redirectUrl = useRef(null)

  const {
    isPaginated,
    type,
    preselectStatus,
    preselectStatuses,
    invoices,
    pagination,
    isFetching,
    types,
  } = useGetInvoicesDataByQuery()

  const {
    selectedInvoices,
    selectedTotal,
    clearSelection,
    toggleInvoice,
  } = useSelectedInvoicesToPay({ invoices, preselectStatuses })

  const noInvoices = !isFetching && !invoices.length
  const selectInvoicesType = invoicesType =>
    navigate(
      `${INVOICES_ROUTES.ROOT}?${queryString.stringify({
        type: invoicesType,
        preselectStatus,
      })}`,
    )

  // this checks for the whole page to be updated and rendered before the redirect to payment service
  // needed because of safari caching, to not get stuck on loading if user presses back after going to payment
  useEffect(
    () => {
      if (
        !isFetching &&
        invoices.length &&
        redirectUrl.current &&
        !selectedInvoices.length
      ) {
        const url = redirectUrl.current
        redirectUrl.current = null

        window.location.assign(url)
      }
    },
    [invoices, isFetching],
  )

  return (
    <LayoutWrapper
      isLoading={isFetching}
      selectedTab={type}
      onTabSelect={selectInvoicesType}
      types={types}
    >
      <ListWrapper noInvoices={noInvoices}>
        {noInvoices ? (
          <NoInvoices
            invoicesType={type}
            onGoToCatalog={() => navigate(ROUTES.DASHBOARD)}
            onSelectAll={() => selectInvoicesType(INVOICE_TYPE_IDS.ALL)}
          />
        ) : (
          <List
            pagination={pagination}
            isFetching={isFetching}
            selectedInvoicesMap={selectedInvoices}
            toggleInvoice={toggleInvoice}
            onPageChange={nextPage =>
              navigate(
                `${INVOICES_ROUTES.ROOT}?${queryString.stringify({
                  type,
                  preselectStatus,
                  page: nextPage,
                })}`,
              )
            }
            invoices={invoices}
            isPaginated={isPaginated}
          />
        )}

        <PaySummary
          selectedInvoicesMap={selectedInvoices}
          clearSelection={clearSelection}
          total={selectedTotal}
          redirectUrl={redirectUrl}
        />
      </ListWrapper>
    </LayoutWrapper>
  )
}

export default compose(withPageView(SCREENS.INVOICES))(InvoicesList)
