import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, generatePath } from 'react-router-dom'

import { ROUTES } from 'consts'

import messages from '../../messages'

const Category = ({ selectedCategory }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const { articles, categoryId, img, title, theme } = selectedCategory
  const bgColor = theme.backgroundColor.value
  const txtColor = theme.textColor.value

  return (
    <div data-test-id="kb-cat-content" className="flex flex-col gap-6 w-full">
      <div
        data-test-id="kb-cat-banner"
        className="flex rounded-3xl justify-between flex-col lg:h-[156px] lg:flex-row"
        style={{ backgroundColor: bgColor }}
      >
        <div className="flex flex-col gap-6 m-8 lg:gap-8 lg:ml-8 lg:self-center">
          <div
            className="rounded-md border border-solid px-1 w-fit"
            style={{ borderColor: txtColor }}
          >
            <span
              className="text-[11px] leading-5 font-medium"
              style={{ color: txtColor }}
            >
              {formatMessage(messages.topicsCount, { count: articles.length })}
            </span>
          </div>
          <h1
            className="text-[28px] leading-10 font-semibold"
            style={{ color: txtColor }}
          >
            {title}
          </h1>
        </div>
        <img src={img} alt="" className="lg:w-[260px]" />
      </div>
      <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-4 lg:gap-6">
        {articles.map(({ title: artTitle, desc, articleId }) => (
          <div
            key={artTitle}
            data-test-id={`kb-cat-article-tile#${artTitle}`}
            className="flex flex-col justify-between p-8 bg-grey-100 rounded-3xl h-[216px]"
          >
            <div className="flex flex-col gap-2">
              <span className="text-blue-900 text-[13px] leading-5 font-medium">
                {artTitle}
              </span>
              <span className="text-grey-800 text-[13px] leading-5 line-clamp-2">
                {desc}
              </span>
            </div>
            <button
              type="button"
              className="bg-grey-200 py-2 px-4 rounded-lg w-fit hover:bg-grey-400"
              onClick={() =>
                navigate(
                  generatePath(ROUTES.KNOWLEDGE_BASE, {
                    category: categoryId,
                    article: articleId,
                  }),
                )
              }
            >
              <span className="text-blue-900 text-[12px] leading-6 font-medium">
                {formatMessage(messages.read)}
              </span>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Category
