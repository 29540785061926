import { put, call, select } from 'redux-saga/effects'
import _identity from 'lodash/identity'
import _get from 'lodash/get'
import _flow from 'lodash/flow'
import _omit from 'lodash/omit'
import { prop, propOr, noop } from 'lodash/fp'
import history from 'utils/history'

import { ROUTES } from 'consts'
import { cameliseDeep } from 'utils'
import genericFetch, { OFFLINE_ERROR } from 'utils/genericFetch'
import { getResponseErrors } from 'utils/redux-form-utils'
import { CRUD_ACTIONS_MAPPER } from 'utils/redux-utils'
import { LOGIN_ERRORS } from 'containers/Auth'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_OFFLINE } from 'containers/App/modalTypes'
import { makeSelectLocale } from 'containers/LanguageProvider/selectors'

import { appConfigActions } from '../actions'

function* genericGetData({
  actions,
  request,
  params,
  dataSelector = _identity,
  method,
}) {
  const successKey = method ? method.toLowerCase() : 'success'
  try {
    if (_get(actions, 'request')) yield put(actions.request())

    const [mainResponse, headers] = yield call(request, params)

    yield put(
      appConfigActions.checkApiVersion({
        apiVersion: headers.get('api-version'),
      }),
    )

    const responseDeserialised = cameliseDeep(mainResponse)

    const responseResult = dataSelector(responseDeserialised)

    if (_get(actions, successKey)) {
      yield put(actions[successKey](responseResult, params))
    }

    return responseResult
  } catch (error) {
    // Headers object is unserializable by redux so we need to omit it to prevent console errors
    const omittedError = _omit(error, ['headers'])
    if (_get(actions, 'failure')) yield put(actions.failure(omittedError))
    throw error
  }
}

export function* getData({ request, apiPreffix, ...sagaParams }) {
  return yield call(genericGetData, {
    ...sagaParams,
    request: _flow(request, genericFetch),
  })
}

// TODO: refactor to more maintainable handling
/* eslint-disable consistent-return */
export function* genericGetDataEnhanced(params, meta) {
  const resolve = propOr(noop, 'resolve')(meta)
  const reject = prop('reject')(meta)

  try {
    const result = yield call(getData, params)

    yield call(resolve, result)

    return result
  } catch (error) {
    const errorStatus = error?.status
    const errorCode = error?.data?.errors?.[0]?.code

    if (document.activeElement) {
      document.activeElement.blur()
    }

    // 401 is handled by axios interceptor, except invalid creds
    if (
      errorStatus === 401 &&
      !LOGIN_ERRORS.INVALID_CREDS.includes(errorCode)
    ) {
      return null
    }

    if (!prop('rethrow404', meta) && errorStatus === 404) {
      history.push(ROUTES.NOT_FOUND)
    } else if (error === OFFLINE_ERROR) {
      yield put(openModal(MODAL_OFFLINE, { hideHeader: true }))
    } else {
      const currentLocale = yield select(makeSelectLocale())
      const responseError = getResponseErrors(error, currentLocale)
      // TODO probably handle 400 here as well - tbDiscussed
      if (errorStatus === 422) {
        error.formErrors = responseError // eslint-disable-line no-param-reassign
      }

      if (reject) return reject(_omit(error, 'headers'))

      throw _omit(error, 'headers')
    }
  }
}

export function* crudSwitch(
  { getSaga, createSaga, updateSaga, deleteSaga },
  action,
) {
  const method = _get(action, 'additionalData.method')

  switch (method) {
    case CRUD_ACTIONS_MAPPER.CREATE:
      yield call(createSaga, action)
      break
    case CRUD_ACTIONS_MAPPER.UPDATE:
      yield call(updateSaga, action)
      break
    case CRUD_ACTIONS_MAPPER.DELETE:
      yield call(deleteSaga, action)
      break
    default:
      yield call(getSaga, action)
  }
}

export * from './notifications'
