import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { values } from 'lodash/fp'

import { LinkBtnV2 } from 'components/Button'
import { formatPrice } from 'utils'
import { PageContent } from 'containers/App/LoggedInLayout/styles'
import { Columns } from 'layouts'
import { FINANCES, trackOverpaymentsClose } from 'services/analytics/finances'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { usePaySummaryTransition } from 'views/Invoices/utils'
import { Invoice as InvoiceIcon } from 'components/Icons'
import { BaseTextV2 } from 'components/Text'
import theme from 'theme'
import { notifyFailure } from 'components/Product/utils'
import { useInitPaymentMutation } from 'containers/Invoices/PaySelection/rtkApi'
import { trackSimpleEvent } from 'services/analytics'
import { useGetFinancesQuery } from 'containers/Invoices/rtkApi'

import messages from '../../../messages'
import SettleModal from '../../../SettleModal'

import {
  PaySummaryContainer,
  PaySummaryInnerContainer,
  TotalToPay,
  SmallText,
  InnerCountContainer,
  ActionsContainer,
  ToPayContainer,
  ButtonsContainer,
  PayBtn,
  OverpaymentsBtn,
  CountContainer,
  InvoiceIconContainer,
} from './styles'

const PaySummary = ({
  selectedInvoicesMap,
  total,
  clearSelection,
  redirectUrl,
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { data: { hasOverpayments } = {} } = useGetFinancesQuery()
  const [initPaymentRequest, { isLoading }] = useInitPaymentMutation()
  const [isOverpaymentsSettlingOpen, setIsOverpaymentsSettlingOpen] = useState(
    false,
  )
  const userData = useSelector(userDataSelector)

  const selectedInvoicesList = useMemo(() => values(selectedInvoicesMap), [
    selectedInvoicesMap,
  ])

  const transitions = usePaySummaryTransition(!!selectedInvoicesList.length)

  const invoicesCount = formatMessage(messages.paySelectedCount, {
    count: selectedInvoicesList.length,
  })
  const invoicesPlural = formatMessage(messages.paySelectedCountPlural, {
    number: selectedInvoicesList.length,
  })

  const handleInitPayment = async () => {
    try {
      const response = await initPaymentRequest(selectedInvoicesList).unwrap()
      trackSimpleEvent(FINANCES.PAYMENT_INIT, {
        store_id: String(userData.storeId),
        amount_to_pay: total,
      })

      // eslint-disable-next-line no-param-reassign
      redirectUrl.current = response.paymentUrl
    } catch (e) {
      dispatch(notifyFailure(formatMessage(messages.tryAgain)))
    }
  }

  return transitions(
    (styled, item) =>
      item && (
        <PaySummaryContainer style={styled} data-test-id="pay-summary">
          {isOverpaymentsSettlingOpen && (
            <SettleModal
              invoicesToPayMap={selectedInvoicesMap}
              invoicesToPayAmount={total}
              onClose={() => {
                setIsOverpaymentsSettlingOpen(false)
                trackOverpaymentsClose({ storeId: userData.storeId })
              }}
            />
          )}

          <PageContent>
            <Columns col={12}>
              <PaySummaryInnerContainer>
                <CountContainer>
                  <InvoiceIconContainer>
                    <InvoiceIcon />
                  </InvoiceIconContainer>
                  <InnerCountContainer>
                    <SmallText
                    >{`${invoicesCount} ${invoicesPlural}`}</SmallText>
                    <LinkBtnV2
                      onClick={clearSelection}
                      data-test-id="pay-summary-clear-btn"
                    >
                      {formatMessage(messages.paySelectClear)}
                    </LinkBtnV2>
                  </InnerCountContainer>
                </CountContainer>
                <ActionsContainer>
                  <ToPayContainer>
                    <SmallText>{formatMessage(messages.toPayLabel)}</SmallText>
                    <TotalToPay>{formatPrice(total)}</TotalToPay>
                  </ToPayContainer>
                  <ButtonsContainer>
                    {hasOverpayments && (
                      <OverpaymentsBtn
                        onClick={() => {
                          setIsOverpaymentsSettlingOpen(true)
                        }}
                      >
                        <BaseTextV2 $fontWeight={theme.fontWeights.medium}>
                          {formatMessage(messages.payWithOverpayments)}
                        </BaseTextV2>
                      </OverpaymentsBtn>
                    )}
                    <PayBtn
                      onClick={handleInitPayment}
                      isLoading={isLoading}
                      data-test-id="pay-summary-pay-btn"
                    >
                      <BaseTextV2
                        $color={theme.colors.white}
                        $fontWeight={theme.fontWeights.medium}
                      >
                        {formatMessage(messages.pay)}
                      </BaseTextV2>
                    </PayBtn>
                  </ButtonsContainer>
                </ActionsContainer>
              </PaySummaryInnerContainer>
            </Columns>
          </PageContent>
        </PaySummaryContainer>
      ),
  )
}

export default PaySummary
